import { classNames } from "@/utils";
import { useRouter } from "next/router";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label?: string;
}

export function Cta({ label, primary = true, size = 'medium', ...props }: ButtonProps) {
  const mode = primary ? 'button_primary' : 'button_secondary';
  const sizeStyle = size === "small" ? "py-1 lg:py-2" : "py-2 lg:py-3"
  // const history = useHistory()
  const router = useRouter()

  const click = () => {
    const currentLocale = router.locale;

    if (primary) {
      window.gtag('event', 'click', {
        'event_category': 'cta',
        'event_label': 'Try now',
        'value': '1'
      });

      const playgroundUrl = `https://playground.deepneuronic.com`;
      window.open(playgroundUrl, '_blank', 'noopener,noreferrer');
    }else{
      window.gtag('event', 'click', {
        'event_category': 'cta',
        'event_label': 'Contact us',
        'value': '1'
      });
      router.push(`/contact`, undefined, { locale: currentLocale });
    }

  }

  return (
    <>
      <button
        onClick={click}
        {...props}
        className={
          classNames(
            [`min-w-[140px] relative px-7 lg:px-8 rounded-lg font-bold ${props.className}  ${sizeStyle}`,].join(' '),
            primary ? "btn-grad" : "bg-transparent  text-cyan-400 btn-grad-border"
          )
        }
      >
        <span>
          {label ?? props.children}
        </span>
      </button>
    </>
  );
}
