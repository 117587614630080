import { ReactNode } from 'react';

interface GridCardProps {
  children: ReactNode;
  onSelect: () => any
  pattern?: boolean
  fixedWidth?: boolean
}

export function GridCard({ children, onSelect, pattern, fixedWidth=true }: GridCardProps) {

  const handleOnMouseMove = (e:any) => {
    const cards = document.getElementsByClassName("card");
    
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i] as HTMLElement;
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      card.style.setProperty("--mouse-x", `${x}px`);
      card.style.setProperty("--mouse-y", `${y}px`);
    }
  }

  return (
    <>
      <div onClick={onSelect} onMouseMove={handleOnMouseMove} className={`h-full  dark:bg-neutral-600 cursor-pointer duration-200 rounded-2xl ${fixedWidth && "max-w-[320px]"}  `}>
        <div className="card-border" ></div>
        <div className={`${pattern ? "pattern" : ""} h-[calc(100%-2px)]  w-[calc(100%-2px)] m-[1px] dark:bg-neutral-900 bg-white flex group flex-col items-center  p-4 rounded-2xl z-[2] relative`} >
        {children}
        </div>
      </div>
    </>
  );
}
