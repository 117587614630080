import { useEffect, useState } from 'react';
import { VideoPlayer } from './VideoPlayer';
//@ts-ignore

import { object as sampleData4 } from "../resources/predictions/sample3"

import { classNames, handleFormatVideoData, handleFormatVideoDataWithParkingStatus } from '../utils';
import { PlayIcon } from '@heroicons/react/24/solid'
import { useDarkMode } from '../hooks/useTheme';

import { PARKING_DELIMITED_AREAS } from '../resources/predictions/parkingAreas';
import { DELIMITED_AREA } from '@/resources/predictions/delimitedAreas';

const sample1 = "/videos/home/parking.mp4"
const sample2 = "/videos/fire_smoke.mp4"
const sample3 = "/sample.mp4"
const sample4 = "/videos/smoke_vehicle.mp4"

import sampleData1 from "../resources/predictions/smoke&vehicles.json"
import sampleData2 from "../resources/predictions/sample2.json"
import sampleData3 from "../resources/predictions/sample1.json"
import sampleData from "../resources/predictions/parkingSample.json"
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-tailwind/react';

interface HomeFeatureSectionProps {
  language: string
}

export function HomeFeatureSection({ language }: HomeFeatureSectionProps) {
  const [predictions, setPredictions] = useState<any>([])
  const [selectedVideo, setSelectedVideo] = useState<any>(sample1)
  const [videoDescription, setVideoDescription] = useState("")
  const [videoTitle, setVideoTitle] = useState("")
  const [videoDimensions, setVideoDimensions] = useState({ width: 1280, height: 720 })
  const [metadata, setMetadata] = useState<any>(sampleData1)
  const [delimitedAreas, setDelimitedAreas] = useState<any[]>([])
  const [areasType, setAreasType] = useState<"PARKING" | "DEFAULT" | null>(null)
  const { t } = useTranslation("common");

  useEffect(() => {
    // const formatted = handleFormatVideoData(sampleData1 as any)
    // setPredictions(formatted)
    // setVideoDescription("Discover our AI's ability to identify diverse vehicles on the road, a game-changer for transportation and urban planning.")
    handleSelectVideo(1)
  }, [])

  const handleSelectVideo = (video: any) => {
    const modelPredictions = {} as any;

    switch (video) {
      case 1:
        setAreasType(null)
        setSelectedVideo(sample4)
        const _predictions1 = sampleData4 as any
        setDelimitedAreas([])
        setMetadata(_predictions1[0])
        setPredictions(handleFormatVideoData(_predictions1[0] as any))
        setVideoDimensions({ width: 1280, height: 720 })
        setVideoDescription("home_feature_description1")
        setVideoTitle("smoke_vehicles")
        break;

      case 2:
        setAreasType("PARKING")
        setSelectedVideo(sample1)
        const formattedParkingSample = handleFormatVideoDataWithParkingStatus(sampleData)
        setDelimitedAreas(PARKING_DELIMITED_AREAS)
        console.log("metadata,", formattedParkingSample[0])
        setMetadata(formattedParkingSample)
        setPredictions(formattedParkingSample)
        setVideoDimensions({ width: 1920, height: 1080 })
        setVideoDescription("home_feature_description1")
        setVideoTitle("parking")
        break;

      case 3:
        setAreasType(null)
        setSelectedVideo(sample2)
        setDelimitedAreas([])
        const _predictions = sampleData2 as any
        // const modelPredictions = {} as any;

        Object.keys(_predictions).forEach((key) => {
          const modelPredictionData = _predictions[key][0]

          Object.keys(modelPredictionData).forEach((innerKey) => {
            if (!modelPredictions[innerKey]) {
              modelPredictions[innerKey] = {};
            }
            modelPredictions[innerKey] = {
              ...modelPredictions[innerKey],
              ...modelPredictionData[innerKey],
            };
          });
        });


        setMetadata(modelPredictions)
        const f = handleFormatVideoData(modelPredictions as any)
        setVideoDimensions({ width: 1920, height: 1080 })
        setPredictions(f)
        setVideoDescription("home_feature_description2")
        setVideoTitle("fire_smoke")
        break;

      case 4:
        setAreasType("DEFAULT")
        setSelectedVideo(sample3)
        setDelimitedAreas(DELIMITED_AREA)
        setMetadata(sampleData3)
        setPredictions(handleFormatVideoData(sampleData3 as any))
        setVideoDimensions({ width: 1280, height: 720 })
        setVideoDescription("home_feature_description1")
        setVideoTitle("vehicles")
        break;

      default:
        break;
    }

    // const formatted = handleFormatVideoData(sampleData1 as any)
    // setPredictions([])s
  }

  return (
    <div className="relative bg-gradient-to-b from-white dark:from-black to-transparent via-transparent" >
      <div className="absolute inset-x-0 -top-[50px] transform-gpu overflow-hidden blur-2xl dark:blur-3xl">
        <svg
          className="relative left-[calc(50%-300px)] z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2084F9" />
              <stop offset={1} stopColor="#06b6d4" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <section className="relative w-full mx-auto max-w-7xl  dark:text-white text-neutral-600 " >
        <div className="w-ful flex items-center justify-center relative z-30" >
          <VideoPlayer areasType={areasType} delimitedAreas={delimitedAreas} videoUrl={selectedVideo} description={videoDescription} videoId={videoTitle} imageSize={videoDimensions} predictions={predictions} metadata={metadata} >
            <div className="flex lg:flex-col items-center gap-4">
              <Tooltip
                className="border border-blue-gray-50 bg-white dark:bg-neutral-900 text-gray-700 dark:text-gray-200 w-60 px-4 py-3 shadow-xl shadow-black/10"
                content={t(`home_feature_description_roadAnomaly`)}
                placement="left">
                <div onClick={() => handleSelectVideo(1)} className="relative group" >
                  <video className="lg:w-60 h-28 rounded-md cursor-pointer" src={sample4} />
                  <div className="absolute inset-0 bg-black/20 cursor-pointer group-hover:bg-black/40" />
                  <div className="absolute w-12 h-12 rounded-full left-1/2 -ml-6 top-1/2 -mt-6 bg-black/50 cursor-pointer group-hover:bg-black/80 flex items-center justify-center" >
                    <PlayIcon className="text-white w-6 h-6" />
                  </div>

                </div>
              </Tooltip>


              <Tooltip
                className="border border-blue-gray-50 bg-white dark:bg-neutral-900 text-gray-700 dark:text-gray-200  w-60 px-4 py-3 shadow-xl shadow-black/10"
                content={t(`home_feature_description_parkingLot`)}
                placement="left">
                <div onClick={() => handleSelectVideo(2)} className="relative group" >
                  <video className="w-full h-28   lg:w-60 rounded-md cursor-pointer" src={sample1} />
                  <div className="absolute inset-0 bg-black/20 cursor-pointer group-hover:bg-black/40" />
                  <div className="absolute w-12 h-12 rounded-full left-1/2 -ml-6 top-1/2 -mt-6 bg-black/50 cursor-pointer group-hover:bg-black/80 flex items-center justify-center" >
                    <PlayIcon className="text-white w-6 h-6" />
                  </div>
                </div>
              </Tooltip>


              <Tooltip
                className="border border-blue-gray-50 bg-white dark:bg-neutral-900 text-gray-700 dark:text-gray-200 w-60 px-4 py-3 shadow-xl shadow-black/10"
                content={t(`home_feature_description2`)}
                placement="left">

                <div onClick={() => handleSelectVideo(3)} className="relative group" >
                  <video className="w-full  h-28   lg:w-60 rounded-md cursor-pointer " src={sample2} />
                  <div className="absolute inset-0 bg-black/20 cursor-pointer group-hover:bg-black/40" />
                  <div className="absolute w-12 h-12 rounded-full left-1/2 -ml-6 top-1/2 -mt-6 bg-black/50 cursor-pointer group-hover:bg-black/80 flex items-center justify-center" >
                    <PlayIcon className="text-white w-6 h-6" />
                  </div>
                </div>
              </Tooltip>

              <Tooltip
                className="border border-blue-gray-50 bg-white dark:bg-neutral-900 text-gray-700 dark:text-gray-200 w-60 px-4 py-3 shadow-xl shadow-black/10"
                content={t(`home_feature_description1`)}
                placement="left">
                <div onClick={() => handleSelectVideo(4)} className="relative group" >
                  <video className="w-full h-28  lg:w-60 rounded-md cursor-pointer " src={sample3} />
                  <div className="absolute inset-0 bg-black/20 cursor-pointer group-hover:bg-black/40" />
                  <div className="absolute w-12 h-12 rounded-full left-1/2 -ml-6 top-1/2 -mt-6 bg-black/50 cursor-pointer group-hover:bg-black/80 flex items-center justify-center" >
                    <PlayIcon className="text-white w-6 h-6" />
                  </div>
                </div>
              </Tooltip>


            </div>
          </VideoPlayer>
        </div>

        {/* <div className="flex items-center gap-4 mt-8"> */}



      </section>
    </div>

  );
}
