type AnimationProps = {
  initial?: number
  final?:number
  delay?:number
  duration?:number
}

export const fromBottom = (initial:number = 20, final:number = 0, delay:number = 0.3) => {
  return {
    hidden: { y: initial, opacity: 0 },
    visible: {
      y: final,
      opacity: 1,
      transition: {
        delay: delay,
        opacity: {duration: 0.3},
      }
    }
  };
}

export const expandWidth = ({initial = 0, final = 250}:AnimationProps) => {
  return {
    hidden: { opacity: 1, width: initial },
    visible: {
      opacity: 1,
      width: final,
      minWidth: final,
      transition: {
        ease: "easeOut",
        width: {duration: 0.3},
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
}

export const expandPaddingRight = ({initial = 52, final = 280}:AnimationProps) => {
  return {
    hidden: { opacity: 1, paddingRight: initial },
    visible: {
      opacity: 1,
      // width: final,
      paddingRight: final,
      transition: {
        ease: "easeOut",
        width: {duration: 0.3},
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
}


export const expandPaddingLeft = ({initial = 45, final = 250}:AnimationProps) => {
  return {
    hidden: { opacity: 1, paddingLeft: initial },
    visible: {
      opacity: 1,
      // width: final,
      paddingLeft: final,
      transition: {
        ease: "easeOut",
        width: {duration: 0.3},
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
}



export const translateFromRight = (initial:number = 200, final:number = 0) => {
  return {
    hidden: { opacity: 0, x: initial },
    visible: {
      opacity: 1,
      x: final,
      transition: {
        ease: "easeOut",
        delay:0.15,
        width: {duration: 0.3},
        delayChildren: 1.3,
        staggerChildren: 1.2
      }
    },
    small: {
      opacity: 1,
      x: 40,
      transition: {
        ease: "easeOut",
        delay:0.1,
        width: {duration: 0.25},
        delayChildren: 1.3,
        staggerChildren: 1.2
      }
    }
  };
}

export const translateFromLeft = (initial:number = -200, final:number = 0) => {
  return {
    hidden: { opacity: 0, x: initial },
    visible: {
      opacity: 1,
      x: final,
      transition: {
        ease: "easeOut",
        delay:0.15,
        width: {duration: 0.3},
        delayChildren: 1.3,
        staggerChildren: 1.2
      }
    },
    small: {
      opacity: 1,
      x: -50,
      transition: {
        ease: "easeOut",
        delay:0.1,
        width: {duration: 0.25},
        delayChildren: 1.3,
        staggerChildren: 1.2
      }
    }
  };
}

export const translateFromTop = (initial:number = -50, final:number = 0) => {
  return {
    hidden: { opacity: 0, y: initial },
    visible: {
      opacity: 1,
      y: final,
      transition: {
        ease: "easeOut",
        delay:0.15,
        width: {duration: 0.3},
        delayChildren: 1.3,
        staggerChildren: 1.2
      }
    }
  };
}

export const hideShow = (delay=0) => {
  return {
    hidden: { opacity: 0, },
    visible: {
      opacity: 1,
      transition: {
        ease: "easeOut",
        delay:0.15,
        opacity: {duration: 0.3},
      }
    }
  };
}

export const FADE_BOTTOM = {
  initial: {
    opacity: 0,
    bottom: - 400
  },
  final: {
    opacity: 1,
    bottom: 0
  }
}


