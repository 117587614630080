import { classNames } from '@/utils';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '@material-tailwind/react';
import Image from 'next/image';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'next-i18next';
const boundingBox = "/images/home/boundingBox.png"
const heatmap = "/images/home/heatmap.png"
const segmentation = "/videos/home/segment.mp4"

interface DetectionTechniquesProps {
}

const list = [
  {
    name: 'Bounding boxes',
    description: "Encapsulate detected objects within images or video frames with clearly defined rectangles, showcasing their exact location, size, and orientation. Enhance security surveillance systems and streamlining inventory management to optimizing automated inspection workflows.",
    image: boundingBox
  },
  {
    name: 'Heatmap',
    description: "Heatmaps allow for quick identification of hotspots, patterns, or trends, enabling clients to easily interpret and make data-driven decisions. Ideal for understanding customer behavior in retail environments or monitoring crowded areas, heatmaps offer a straightforward way to analyze complex spatial data.",
    image: heatmap
  },
  {
    name: 'Segmentation',
    description: "Segmentation divides an image into meaningful segments, facilitating easier and more accurate analysis. This technique is essential for detailed scene understanding, from medical imaging to autonomous driving. By isolating and analyzing distinct objects and areas, segmentation provides crucial insights and supports precise decision-making across various applications.",
    video: segmentation,
  },
]

export function DetectionTechniques({ }: DetectionTechniquesProps) {


  const [activeTab, setActiveTab] = useState<any>(list[0]);
  const { t } = useTranslation();
  console.log(activeTab)
  return (
    <>
      <section className="w-full mx-auto container max-w-7xl mt-20 lg:mt-32  bg-gradient-to-b relative dark:text-white text-neutral-600 " >
        <div className="relative flex flex-col lg:flex-row items-center gap-4 lg:gap-8 mx-auto max-w-6xl w-full overflow-hidden min-h-[420px] p-10 rounded-3xl  dark:bg-neutral-900 bg-white" >
          <Tabs
            className="max-w-[500px] min-h-[300px]"
            animate={{
              initial: { y: 200 },
              mount: { y: 0, transition: { duration: 0.100 } },
              unmount: { y: 200, transition: { duration: 0.100 } },

            }} id="custom-animation" value={list[0].name}>
            <TabsHeader
              className="bg-transparent items-center flex"
              indicatorProps={{
                className: "bg-neutral-400/20 shadow-none text-neutral-600 dark:text-neutral-100 rounded-2xl",
              }}
            >
              {list.map((item, index) => (
                <Tab key={`${item.name}`} value={item.name}
                  className={classNames(
                    activeTab === item.name ? "dark:text-neutral-100 text-neutral-600 d h-[48px] text-xs md:text-sm" : "h-[48px] text-xs md:text-sm dark:text-neutral-100 text-neutral-600",
                    "flex items-center justify-center delay-200 p-2 font-semibold"
                  )}
                  onClick={() => setActiveTab(item)}
                >
                  {t(`${item.name}`)}
                </Tab>
              ))}
            </TabsHeader>

            <TabsBody>
              {list.map((item, index) => (
                <TabPanel key={index} value={item.name}>
                  <div className="lg:flex justify-between gap-10 w-full" >


                    <div className="md:flex-1 h-full min-w-[220px] flex flex-col justify-between" >
                      <div className="mt-8" >
                        <p className="text-lg font-semibold" >
                          {t(`${item.name}`)}
                        </p>

                        <div className="w-full mt-2 block lg:hidden" >
                          {item.image ? (
                            <Image
                              width={700}
                              height={399}
                              className=" w-full rounded-lg object-contain"
                              src={activeTab.image ?? ""}
                              alt={activeTab.name}
                            />
                          ) : (
                            <video
                              autoPlay
                              loop
                              className=" w-full rounded-lg object-contain"
                              src={item.video}></video>
                          )}

                        </div>

                        <p className="mt-2 text-sm dark:text-gray-400 text-gray-600" >
                          {t(`${item.description}`)}
                        </p>
                      </div>

                      <div className="mt-2 text-sm dark:text-gray-200 text-neutral-900" >

                      </div>
                      <div className="w-full flex justify-center lg:justify-end mt-4 lg:mt-0" >

                        {/* <Button>{t("cta")}</Button> */}
                      </div>
                    </div>
                  </div>
                </TabPanel>
              ))}
            </TabsBody>

          </Tabs>
          <div className="w-full hidden lg:block" >
            {activeTab.image ? (
              <Image
                width={700}
                height={399}
                className=" w-full rounded-lg object-contain"
                src={activeTab.image ?? ""}
                alt={activeTab.name}
              />
            ) : (
              <video
                autoPlay
                loop
                className=" w-full rounded-lg object-contain"
                src={activeTab.video}></video>
            )}

          </div>
        </div>

      </section>
    </>
  );
}
