import { ReactNode, useState } from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
} from "@material-tailwind/react";

interface CustomAccordionProps {
  header: string
  body: string
}

function Icon({  open }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

export function CustomAccordion({ header, body }: CustomAccordionProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen((o) => !o);

  return (
    <>
      <Accordion open={open} icon={<Icon open={open} className="mb-2 rounded-lg border border-blue-gray-100 px-4 " />}>
        <AccordionHeader 
           className={`border-b-0 transition-colors text-left text-lg ${
            open ? "text-blue-500 hover:!text-blue-700" : "dark:text-gray-200 dark:hover:text-gray-400"
          }`}
        onClick={handleOpen} >{header}</AccordionHeader>
        <AccordionBody className="pt-0 text-base font-normal text-gray-600 dark:text-gray-300 ">
          {body}
        </AccordionBody>
      </Accordion>
    </>
  );
}
