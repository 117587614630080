import { Button } from './Button';
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router';

interface CtaCardProps {
}

//TODO update content and button link
export function CtaCard({ }: CtaCardProps) {
  const { t, i18n } = useTranslation("common");
  const router = useRouter()

  const handleCta = () => {
      window.gtag('event', 'click', {
        'event_category': 'cta',
        'event_label': 'Try now',
        'value': '1'
      });

      const playgroundUrl = `https://playground.deepneuronic.com`;
      window.open(playgroundUrl, '_blank', 'noopener,noreferrer');
  }
  
  return (
    <div className="mx-auto mt-16 max-w-6xl w-full overflow-hidden h-[400px] p-10 relative rounded-3xl shadow-xl dark:shadow-primary-700/30 dark:text-white text-neutral-600 " >
      <div className="absolute top-0 left-0 w-full h-[500px] flex z-0" >
        <div className="w-[calc(50%-100px)] dark:bg-neutral-900 bg-white h-full" ></div>
        <div className="w-[calc(50%+100px)] bg-primary-700 h-full border-l-[200px] border-b-[500px] dark:border-l-neutral-900 border-l-white border-b-transparent" ></div>
      </div>

      <motion.div
        key={"ctaCard"}
        className="flex z-10 relative w-full h-full items-center justify-between "
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
   
        <div className="md:w-1/2 max-w-[470px]" >
          <h3 className=" text-lg md:text-xl lg:text-2xl font-bold" >{t("cta_card_title")}</h3>
          <p className="mt-8 dark:text-gray-400 text-gray-600" >{t("cta_card_description1")}</p>
          <p className="mt-4 dark:text-gray-400 text-gray-600" >{t("cta_card_description2")}</p>
          <Button onClick={handleCta} className="mt-8" >{t("cta")}</Button>
        </div>

        <div className="hidden md:block w-1/2">
          <img src={"/images/home/card3.svg"} alt="card3" />
        </div>

      </motion.div>

    </div>
  );
}
