import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { classNames } from '@/utils';

export function LanguageToggle() {
  const [isOpen, setIsOpen] = useState(false)
  const { t, i18n } = useTranslation("common");
  const router = useRouter();
  const toggleRef = useRef(null);

  const change_language = (selected_language: string) => {
    // localStorage.setItem('language', selected_language);
    setIsOpen(false)
    i18n.changeLanguage(selected_language);
    router.push(router.asPath, router.asPath, { locale: selected_language });
  }

  useEffect(() => {
    // Function to detect click outside the toggle
    function handleClickOutside(event: any) {
      //@ts-ignore
      if (toggleRef.current && !toggleRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleRef]);

  return (
    <div className="select-none" ref={toggleRef}>
      <div className={classNames(
        " rounded-xl fixed bg-white/30 z-50 bottom-16 right-5 lg:right-10 cursor-pointer",
        isOpen ? "block" : "hidden"
      )} >
        <div onClick={() => change_language("pt")} className="flex items-center gap-2 px-4 hover:bg-white/50 py-2 rounded-xl " >

          <Image width={26} height={26} src="icons/portugal_icon.svg" alt="portugal" />
          <p>
            PT
          </p>
        </div>
        <div onClick={() => change_language("br")} className="flex items-center gap-2 px-4 py-2  hover:bg-white/50 rounded-xl" >
          <Image width={26} height={26} src="icons/brazil_icon.svg" alt="portuguese" />
          <p>PT-BR</p>
        </div>

        <div onClick={() => change_language("en")} className="flex items-center gap-2 px-4 py-2  hover:bg-white/50 rounded-xl" >
          <Image width={26} height={26} src="icons/british_icon.svg" alt="english" />
          <p>EN</p>
        </div>
      </div>

      <div className="px-4 py-2 rounded-xl fixed bg-white/20 z-50 bottom-5 right-5 lg:right-10 cursor-pointer">
        <div onClick={() => setIsOpen((i) => !i)} className="flex items-center gap-2" >
          {router.locale === "pt" ? (
            <>
              <Image width={26} height={26} src="icons/portugal_icon.svg" alt="portuguese" />
              <p>PT</p>
            </>
          ) : router.locale === "br" ? (
            <>
              <Image width={26} height={26} src="icons/brazil_icon.svg" alt="brazilian portuguese" />
              <p>PT-BR</p>
            </>
          ) : (
            <>
              <Image width={26} height={26} src="icons/british_icon.svg" alt="english" />
              <p>EN</p>
            </>
          )}

        </div>
      </div>
    </div>
  );
}
