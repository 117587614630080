export function shuffleArray(array:any[]) {
  let newArray = [...array]; // Copy the array to prevent mutating the original array
  for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]]; // Swap elements
  }
  return newArray; // Return the shuffled array
}

export const rgbToHex = (r:any, g:any, b:any) => {
  const hex = (x:any) => {
    const hex = x.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${hex(r)}${hex(g)}${hex(b)}`;
};