import { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'

interface Props {
  name: string
  label?: string
}
type InputProps = JSX.IntrinsicElements['input'] & Props

export function Input({ name, label, ...props }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name)
  const [pwVisible, setPwVisible] = useState(false)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <div className="relative group w-full" >
      {label && <label className="text-sm font-medium " htmlFor={fieldName}>{label}</label>}
      <input
        {...props}
        className={`block h-10 w-full p-2.5 rounded-md border border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm ${error && "border-red-600"} ${props.className}`}
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        type={pwVisible ? "text" : props.type}
        onChange={clearError}
      />
      {props.type === "password" && (
        <>
          {pwVisible ? (
            <EyeSlashIcon onClick={() => setPwVisible((e) => !e)} className="w-6 h-6 text-primary-500 absolute top-2 right-2 cursor-pointer invisible group-hover:visible" />
          ) : (
            <EyeIcon onClick={() => setPwVisible((e) => !e)} className="w-6 h-6 text-primary-500 absolute top-2 right-2 cursor-pointer invisible group-hover:visible" />
          )}
        </>
      )}
      {error && <p className="text-red-600 text-xs">{error}</p>}
    </div>
  );
}
