import { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { USE_CASES, UseCaseType } from '../resources/constants';
import { Button } from './Button';
import { shuffleArray } from '../utils';
import { GridCard } from './GridCard';
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router';
import Image from 'next/image';
import { FADE_BOTTOM } from '@/animations';

const cameraIcon = "/icons/home/camera.svg"
const connectivityIcon = "/icons/home/connectivity.svg"
const notificationIcon = "/icons/home/notification.svg"
const secureIcon = "/icons/home/secure.svg"
const stabilityIcon = "/icons/home/stability.svg"
const customIcon = "/icons/home/custom.svg"

interface HomeGridSectionProps {
}

const CARDS = [
  {
    title: "Precision-Driven Alert System",
    description: "Leveraging our advanced artificial intelligence models, we significantly diminish false alarms—by up to 98%.",
    icon: notificationIcon,
  },
  {
    title: "Real-Time Recognition",
    description: "DeepNeuronic guarantees immediate notification upon detection of any critical event with its swift and accurate response capabilities.",
    icon: cameraIcon,
  },
  {
    title: "Flexible Connectivity",
    description: "Choose to process your video data in the cloud for ease and scalability, or on your internal servers for maximum control and security, with our on-premise and cloud solutions.",
    icon: connectivityIcon,
  },
  {
    title: "Data Autonomy",
    description: "Your data sovereignty is paramount.By processing video analysis locally, we ensure that your operations remain within the bounds of GDPR compliance",
    icon: secureIcon,
  },
  {
    title: "Rock-Solid Stability",
    description: "Our systems are crafted to stay operational and efficient, overcoming obstacles like slow, intermittent, or temporarily lost connections.",
    icon: stabilityIcon,
  },
  {
    title: "Custom Solutions",
    description: "Understanding that one size does not fit all, DeepNeuronic specializes in crafting custom solutions for your needs and specifications.",
    icon: customIcon,
  },



]

export function HomeGridSection({ }: HomeGridSectionProps) {
  const { t, i18n } = useTranslation("common");
  const router = useRouter()

  return (
    <>
      <section className="w-full mx-auto max-w-7xl mt-20 lg:mt-32  bg-gradient-to-b relative dark:text-white text-neutral-600 " >
        <div className="absolute inset-x-0 top-[0] transform-gpu blur-3xl opacity-70">
          <svg
            className="relative left-[calc(50%)] h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-10rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
              fillOpacity=".3"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#06b6d4" />
                <stop offset={1} stopColor="#2084F9" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="text-center" >
          <motion.div className="w-full flex items-center justify-center"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <div className="w-full dark:h-[2px] h-[1px] bg-gradient-to-l from-neutral-500 to-background-900" ></div>
            <h3 className="font-light text-base md:text-lg lg:text-xl dark:text-white text-gray-500 mx-4" >{t("Why DeepNeuronic?")}</h3>
            <div className="w-full dark:h-[2px] h-[1px] bg-gradient-to-r from-neutral-500 to-background-900" ></div>
          </motion.div>
        </div>

        <div className="w-full mx-auto max-w-7xl p-10" >

          <div id="cards" className="lg:mt-12 content-center  grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3 sm:gap-y-8 lg:gap-x-4 items-center justify-center relative z-10">
            {CARDS.map((i) => (
              <GridCard pattern fixedWidth={false} key={i.title} onSelect={() => console.log(i.title)}>
                <div className=" w-full  overflow-hidden r" >
                  <Image width={36} height={36} className=" duration-200" src={i.icon} alt={i.title} />
                </div>
                <div className="w-full text-left mt-4 " >
                  <p className="text-lg" >
                    {t(`${i.title}`)}
                  </p>
                  <p className="mt-2 dark:text-gray-400 text-gray-600 " >
                    {t(`${i.description}`)}
                  </p>
                </div>
              </GridCard>
            ))}
          </div>


        </div>
      </section>
    </>
  );
}
