const  Cvf = "/images/cvf.png"
const  XEurope = "/images/x_europe.png"
const  p2020 = "/images/p2020.png"
const  Calling2Scale = "/images/eit.png"
const WSA = "/images/WSA_logo.png"

import { useDarkMode } from '@/hooks/useTheme';
import { classNames } from '@/utils';
import { useTranslation } from 'next-i18next';

export function AwardsSection() {
  const { t, i18n } = useTranslation();
  const { theme } = useDarkMode()

  return (
    <section className="mt-36 w-full dark:text-white text-neutral-600 " >
      <div className="w-full mt-20 mx-auto max-w-7xl flex flex-col lg:flex-row items-center justify-center " >
        <div className="border-l border-neutral-300 dark:text-neutral-400 text-gray-600" >
          <p className="ml-4 text-sm " >{t("AWARDS & NEWS")}</p>
          <p className="pl-[13px] -ml-[2px] mt-2 border-l-[3px] border-white dark:text-white text-neutral-900 " >{t("Award-winning projects")}</p>
          <p className="ml-4 mt-4" >{t("Our work has been recognized at international conferences and we have been granted external funding to pursue our goals")}</p>
        </div>
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-10 items-center relative z-10 w-1/2 text-center text-sm  dark:text-neutral-400 text-gray-600">
          <div className="flex flex-col items-center" >
            <img className={classNames(
              "h-20",
              theme === "dark" ? "" : "invert"
            )} src={Cvf} alt="cvf" />
            <p>WACV 2022</p>
          </div>
          <div className="flex flex-col items-center" >
            <img className={classNames(
              "h-20",
              theme === "dark" ? "" : "invert"
            )} src={XEurope} alt="XEurope" />
            <p>X-EUROPE COHORT</p>
          </div>
          <div className="flex flex-col items-center" >
            <img className={classNames(
              "h-20",
              theme === "dark" ? "" : "invert"
            )} src={p2020} alt="p2020" />
            <p>PORTUGAL 2020 (P2020)</p>
          </div>
          <div className="flex flex-col items-center " >
            <img className="h-20 " src={Calling2Scale} alt="Calling2Scale" />
            <p>CALLING2SCALE BOOTCAMP</p>
          </div>
          <div className="flex flex-col items-center col-span-2" >
            <img className="h-20 dark:invert dark:grayscale" src={WSA} alt="WSA" />
            <p>World Summit Awards Portugal 2023</p>
            <p>#APDC #digitalbusinesscommunity #WSA #WSAPortugal</p>
          </div>
        </div>
      </div>
    </section>
  );
}
