import { Button } from './Button';
import { UseCaseType } from '../resources/constants';
import { Carousel } from "@material-tailwind/react";
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

interface UseCaseVideoProps {
  useCase: UseCaseType

}

export function UseCaseVideo({ useCase }: UseCaseVideoProps) {
  const router = useRouter();

  const { t, i18n } = useTranslation();

  const handleSelectIndustry = (id: string) => {
    if (id !== "government") {
      router.push(`/industries/${id}`)
    }
  }

  return (
    <div className="w-full lg:h-[700px] p-12 lg:py-16 dark:text-white text-neutral-600 relative border border-white rounded-3xl dark:bg-neutral-900 bg-white flex items-center justify-between gap-10" >
      <div>
        <div className="lg:flex justify-between gap-10 w-full" >
          <div className=" w-full md:max-w-[700px] max-h-[300px] md:max-h-[400px] md:h-[400px] rounded-lg overflow-hidden" >
            <Carousel
              className="rounded-xl">
              {useCase.examples?.map((imgUrl, index) => (
                <img
                  key={`img ${index}`}
                  // objectFit='cover'
                  // layout='fill'
                  src={imgUrl}
                  alt={`img ${index}`}
                  className="h-full w-full object-cover object-left-top"
                />
              ))}
            </Carousel>
          </div>

          <div className="md:flex-1 h-full min-w-[260px] flex flex-col justify-between" >
            <div className="mt-12" >
              <p className="text-lg font-semibold" >
                {t("Industries")}
                {/* Monitor traffic flow and detect potential safety concerns in real-time with our advanced computer vision technology. */}
              </p>
              <div className="flex gap-2 my-2" >
                {useCase.industries.map((industry) => (
                  <div key={industry.id} onClick={() => handleSelectIndustry(industry.id)} className="py-1 px-3 bg-light-blue-50 text-primary-600 rounded-xl cursor-pointer hover:bg-primary-700 hover:text-white text-sm" >
                    {t(`${industry.name}`)}
                  </div>
                ))}
              </div>


              <p className="text-lg font-semibold mt-8 " >
                {t(`${useCase?.label}`)}
                {/* Monitor traffic flow and detect potential safety concerns in real-time with our advanced computer vision technology. */}
              </p>
              <p className="mt-2 text-sm dark:text-gray-400 text-gray-600" >
                {t(`${useCase?.description}`)}
                {/* Take action to alleviate traffic and improve safety, by monitoring: */}
              </p>
            </div>

            <div className="mt-2 text-sm dark:text-gray-200" >

            </div>
            <div className="w-full flex justify-end mt-8" >

              <Button> {t("cta")}</Button>
            </div>
          </div>
        </div>

      </div>



    </div>
  );
}
