export function isPointInsideRect(px:number, py:number, rect:any) {
  const [rectLeft, rectTop, rectWidth, rectHeight] = rect;
  return (px > rectLeft && px < rectLeft + rectWidth && py > rectTop && py < rectTop + rectHeight);
}

export function isPointInsidePolygon(px:number, py:number, polygon:any) {
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let [xi, yi] = polygon[i];
      let [xj, yj] = polygon[j];
      const intersect = ((yi > py) !== (yj > py)) && (px < (xj - xi) * (py - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }
  return inside;
}

export function doEdgesIntersect(ax1:number, ay1:number, ax2:number, ay2:number, bx1:number, by1:number, bx2:number, by2:number) {
  const det = (ax2 - ax1) * (by2 - by1) - (bx2 - bx1) * (ay2 - ay1);
  if (det === 0) return false;  // lines are parallel

  const lambda = ((by2 - by1) * (bx2 - ax1) + (bx1 - bx2) * (by2 - ay1)) / det;
  const gamma = ((ay1 - ay2) * (bx2 - ax1) + (ax2 - ax1) * (by2 - ay1)) / det;

  return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
}

export function doPolygonAndRectIntersect(polygon:any, rect:any) {
  const [rectLeft, rectTop, rectWidth, rectHeight] = rect;
  const rectPoints = [
      [rectLeft, rectTop],
      [rectLeft + rectWidth, rectTop],
      [rectLeft + rectWidth, rectTop + rectHeight],
      [rectLeft, rectTop + rectHeight]
  ];

  for (const point of polygon) {
      if (isPointInsideRect(point[0], point[1], rect)) return true;
  }

  for (const point of rectPoints) {
      if (isPointInsidePolygon(point[0], point[1], polygon)) return true;
  }

  for (let i = 0; i < polygon.length; i++) {
      const nextIndex = (i + 1) % polygon.length;  // To loop to the first point for the last edge

      for (let j = 0; j < rectPoints.length; j++) {
          const nextRectIndex = (j + 1) % rectPoints.length;

          if (doEdgesIntersect(
              polygon[i][0], polygon[i][1], polygon[nextIndex][0], polygon[nextIndex][1],
              rectPoints[j][0], rectPoints[j][1], rectPoints[nextRectIndex][0], rectPoints[nextRectIndex][1]
          )) {
              return true;
          }
      }
  }

  return false;
}

//TODO expand function to adjust coordinates
// export function reduceRectSize(rect:any, multiplier=0.4) {
//   // Calculate reduced dimensions
//   const reducedWidth = rect[2] * 0.4; // Adjust the multiplier as needed (0.5 for 50%, 0.25 for 25%, etc.)
//   const reducedHeight = rect[3] * 0.4; // Adjust the multiplier as needed

//   // Calculate new coordinates (so the reduced rectangle is centered at the same point as the original rectangle)
//   const newLeft = rect[0] + (rect[2] - reducedWidth) / 2;
//   const newTop = rect[1] + (rect[3] - reducedHeight) / 2;

//   return [newLeft, newTop, reducedWidth, reducedHeight];
// }


export function reduceRectSize(rect: any, multiplier = 0.4, offsetPercentage = 0.15) {
  // Calculate reduced dimensions
  const reducedWidth = rect[2] * 0.4;
  const reducedHeight = rect[3] * 0.4;

  // Calculate new coordinates 
  // Adjust the newLeft to be centered
  const newLeft = rect[0] + (rect[2] - reducedWidth) / 2;

  // Adjust the newTop to be slightly towards the bottom based on the offsetPercentage
  // But ensure it doesn't go outside the original rectangle
  const maxAllowedTop = rect[1] + rect[3] - reducedHeight;
  const desiredNewTop = rect[1] + (rect[3] - reducedHeight) * (1 + offsetPercentage);
  const newTop = Math.min(desiredNewTop, maxAllowedTop);

  return [newLeft, newTop, reducedWidth, reducedHeight];
}



