import { ImagePredictionsType, VideoPredictionsType } from "../types/PredictionType";

export const classToColorMap: { [key: string]: string } = {
  walking: "#999999",
  running: "#999999",
  standing: "#999999",
  standing_up: "#999999",
  sitting_down: "#999999",
  jumping: "#999999",
  riding: "#999999",
  climbing: "#999999",
  lying: "#e4a872",
  throwing: "#e4a872",
  falling: "#e4a872",
  carrying_weapon: "#e4a872",
  abandoned_object: "#E60000",
  fighting: "#E60000",
  stealing: "#E60000",
  shooting: "#E60000",
  vandalizing: "#E60000",
  fire_raising: "#E60000",
  fire_explosion: "#E60000",
  road_accident: "#E60000",
  person: "#262626",
  bicycle: "#262626",
  car: "#262626",
  bus: "#262626",
  truck: "#262626",
  motorcycle: "#262626",
  backpack: "#262626",
  handbag: "#262626",
  suitcase: "#262626",
  baseball_bat: "#E60000",
  knife: "#E60000",
  scissors: "#E60000",
  gun: "#E60000",
  fire: "#E60000",
  smoke: "#e4a872",
  motorbike: "#262626",
  aeroplane: "#262626",
  train: "#262626",
  boat: "#262626",
  traffic_light: "#262626",
  fire_hydrant: "#262626",
  stop_sign: "#262626",
  parking_meter: "#262626",
  bench: "#262626",
  bird: "#262626",
  cat: "#262626",
  dog: "#262626",
  horse: "#262626",
  sheep: "#262626",
  cow: "#262626",
  elephant: "#262626",
  bear: "#262626",
  zebra: "#262626",
  giraffe: "#262626",
  umbrella: "#262626",
  tie: "#262626",
  frisbee: "#262626",
  skis: "#262626",
  snowboard: "#262626",
  sports_ball: "#262626",
  kite: "#262626",
  baseball_glove: "#262626",
  skateboard: "#262626",
  surfboard: "#262626",
  tennis_racket: "#262626",
  bottle: "#262626",
  wine_glass: "#262626",
  cup: "#262626",
  fork: "#E60000",
  spoon: "#262626",
  bowl: "#262626",
  banana: "#262626",
  apple: "#262626",
  sandwich: "#262626",
  orange: "#262626",
  broccoli: "#262626",
  carrot: "#262626",
  hot_dog: "#262626",
  pizza: "#262626",
  donut: "#262626",
  cake: "#262626",
  chair: "#262626",
  sofa: "#262626",
  pottedplant: "#262626",
  bed: "#262626",
  diningtable: "#262626",
  toilet: "#262626",
  tvmonitor: "#262626",
  laptop: "#262626",
  mouse: "#262626",
  remote: "#262626",
  keyboard: "#262626",
  cell_phone: "#262626",
  microwave: "#262626",
  oven: "#262626",
  toaster: "#262626",
  sink: "#262626",
  refrigerator: "#262626",
  book: "#262626",
  clock: "#262626",
  vase: "#262626",
  teddy_bear: "#262626",
  hair_drier: "#262626",
  toothbrush: "#262626",
  graffiti: "#FF751A",
  low_speed: "#FF751A",
  out_of_bounds: "#FF751A",
  stopped: "#E60000",
  wrong_way: "#E60000",
  transgressing: "#E60000",
  queue: "#262626",
  lane: "#262626",
  entering: "#262626",
  leaving: "#262626",
  head: "#262626",
  seagull: "#E60000"
};

const smallPersonalObjects = new Set([
  "backpack",
  "handbag",
  "suitcase",
  "baseball_bat",
  "knife",
  "scissors",
  "gun",
  "umbrella",
  "tie",
  "frisbee",
  "skis",
  "snowboard",
  "sports_ball",
  "kite",
  "baseball_glove",
  "skateboard",
  "surfboard",
  "tennis_racket",
  "bottle",
  "wine_glass",
  "cup",
  "fork",
  "spoon",
  "bowl",
  "banana",
  "apple",
  "sandwich",
  "orange",
  "broccoli",
  "carrot",
  "hot_dog",
  "pizza",
  "donut",
  "cake",
  "laptop",
  "mouse",
  "remote",
  "keyboard",
  "cell_phone",
  "microwave",
  "toaster",
  "vase",
  "teddy_bear",
  "hair_drier",
  "toothbrush",
]);

const itemStyles = {
  occupied: "border-red-500 hover:border-red-700 bg-red-700/10 hover:bg-transparent",
  person: "border-neutral-700 hover:border-neutral-800 bg-neutral-900/20 hover:bg-transparent",
  car: "border-blue-500 hover:border-blue-600 bg-blue-900/20 hover:bg-transparent",
  bus: "border-neutral-500 hover:border-neutral-600 bg-neutral-900/20 hover:bg-transparent",
  truck: "border-green-500 hover:border-green-600 bg-green-900/30 hover:bg-transparent",
  fire: "border-red-500 hover:border-red-700 bg-red-700/10 hover:bg-transparent",
  backpack: "border-neutral-700 hover:border-neutral-800",
  handbag: "border-neutral-700 hover:border-neutral-800",
  suitcase: "border-neutral-700 hover:border-neutral-800",
  lying: "border-[#e4a872] hover:border-[#e4a872]  bg-[#e4a872]/10 hover:bg-transparent",
  throwing: "border-[#e4a872] hover:border-[#e4a872]",
  falling: "border-[#e4a872] hover:border-[#e4a872]",
  carrying_weapon: "border-[#e4a872] hover:border-[#e4a872]",
  smoke: "border-[#e4a872] hover:border-[#e4a872] bg-[#e4a872]/10 hover:bg-transparent",
  graffiti: "border-[#e4a872] hover:border-[#e4a872]",
  vandalizing: "border-red-500 hover:border-[#E60000]",
  fighting: "border-red-500 hover:border-[#E60000]",
  walking: "border-[#999999] hover:border-[#999999]",
  running: "border-[#999999] hover:border-[#999999]",
  standing: "border-[#999999] hover:border-[#999999]",
  standing_up: "border-[#999999] hover:border-[#999999]",
  sitting_down: "border-[#999999] hover:border-[#999999]",
  jumping: "border-[#999999] hover:border-[#999999]",
  riding: "border-[#999999] hover:border-[#999999]",
  climbing: "border-[#999999] hover:border-[#999999]",
} as any;

export const getColorStyles = (item: string, filter: any, area?:any) => {
  const lowerCaseItem = item.toLocaleLowerCase();
  const style = itemStyles[lowerCaseItem] || "border-neutral-700 hover:border-neutral-800";

  if(area){
    return style
  }
  
  if (filter.includes(lowerCaseItem)) {
    return style;
  } else {
    return "hidden";
  }
};

export const getColorByClass = (className: string) => {
  return classToColorMap[className] || "#000000"; // Default to black if className is not found
};

export const isSmallPersonalObject = (item: string): boolean => {
  return smallPersonalObjects.has(item.toLocaleLowerCase());
};

export function getVideoDetectedClasses(predictions: VideoPredictionsType, removedClasses = [""]): Set<string> {
  const detectedClasses = new Set<string>();

  predictions.forEach(frame => {
    frame.objects.forEach(object => {
      object.classes.forEach(({ class: className }) => {
        if (!removedClasses.includes(className)) {
          detectedClasses.add(className);
        }
      });
    });
  });

  return detectedClasses;
}

export function getImageDetectedClasses(predictions: ImagePredictionsType): Set<string> {
  const detectedClasses = new Set<string>();

  predictions.forEach(object => {
    object.classes.forEach(({ class: className }) => {
      detectedClasses.add(className);
    });
  });

  return detectedClasses;
}
