import Balancer from 'react-wrap-balancer'
import { useTranslation } from 'next-i18next'
import { IndustryVideoSection } from './IndustryVideoSection'
import Image from 'next/image'

const HeroBg = "/images/computer_vision.png"

interface IndustryHeroProps {
  title: string
  heroImg?: any
  industry: any
}

export function IndustryHero({ title, industry }: IndustryHeroProps) {
  const { t } = useTranslation();

  return (
    <div className="isolate dark:bg-transparent  h-full  w-full text-white">
      {/* <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
        <svg
          className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#42a5f5" />
              <stop offset={1} stopColor="#3849ab" />
            </linearGradient>
          </defs>
        </svg>
      </div> */}

      {/* Overlay */}
      <div className=" w-full   dark:opacity-75 top-0 opacity-90 bg-gradient-to-r " >

        <Image objectFit='cover' layout='fill' className="w-full h-[900px] object-cover" src={HeroBg} alt="bg" />
        <div className="h-[900px] w-full absolute z-10 top-0 opacity-50 bg-black" />
        <div className="h-[900px] w-full absolute  z-10 bottom-0 opacity-75 bg-gradient-to-r from-transparent to-primary-700"/>
        {/* <div className="h-[900px] w-full absolute  bg-gradient-to-b z-10 top-0 from-black via-black/50  to-black" /> */}

      </div>

      <main className="w-full flex justify-center  sm:mt-24 md:mt-28" >
        <div className="relative px-6 lg:px-8 max-w-7xl ">
          <div className="mx-auto pt-10 pb-14 sm:pt-20 sm:pb-12 ">

            <div className="text-center p-4" >
              <h1 className="text-2xl  sm:text-3xl  md:text-4xl lg:text-5xl font-bold tracking-tight ">
                <Balancer>
                  {t("Computer vision for")}
                  <span
                    className=" bg-gradient-to-r from-secondary-500 via-primary-500 to-secondary-400 text-transparent bg-clip-text bg-300% animate-gradient"
                  >
                    {" "}{t(`${title}`)}{" "}
                  </span>
                </Balancer>
              </h1>
              {/* <p className="mt-2 text-2xl sm:text-3xl leading-8 text-gray-500 ">
              Elevating Industry Standards with Our AI-Powered Solutions.
              </p> */}
              <div className="w-full flex mt-6 items-center justify-center" >
                {/* <Cta >Try now</Cta> */}
              </div>

            </div>



            {/* <img className="min-w-[900px] " src={HeroImg} alt="hero" /> */}

            {/* Pattern */}
            <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
              <svg
                className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                viewBox="0 0 1155 678"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                  fillOpacity=".3"
                  d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                />
                <defs>
                  <linearGradient
                    id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                    x1="1155.49"
                    x2="-78.208"
                    y1=".177"
                    y2="474.645"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3849ab" />
                    <stop offset={1} stopColor="#42a5f5" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

          </div>
        </div>


      </main>


      <IndustryVideoSection industry={industry} />

    </div>
  )
}
