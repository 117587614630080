/**Custom hook for handling window dimensions if the window resizes, it updates the dropdown menu position */

import { useState, useCallback, useLayoutEffect, useEffect } from "react";
//-----------------------------------------------------------------------------

// get element dimensions
const getDimensions = (element: HTMLElement) => element.getBoundingClientRect();

export function useDimensions(responsive = true): [(e: HTMLElement) => void, DOMRect | null, HTMLElement | null] {
  const [dimensions, setDimensions] = useState<DOMRect | null>(null);
  const [element, setElement] = useState<HTMLElement | null>(null);

  // Set/Register element
  const hook = useCallback((e: HTMLElement) => setElement(e), []);
  // // Utility to determine if we're running on the server or client
  // const isServer = typeof window === 'undefined';
  // const useIsomorphicLayoutEffect = isServer ? useEffect : useLayoutEffect;
  
  // Event listener that updates dimensions if window is resized
  useEffect(() => {
    if (element) {
      const updateDimensions = () => {
        window.requestAnimationFrame(() => {
          setDimensions(getDimensions(element));
        });
      };

      updateDimensions();

      if (responsive) {
        window.addEventListener("resize", updateDimensions);

        return () => {
          window.removeEventListener("resize", updateDimensions);
        };
      }
    }
  }, [element, hook, responsive]);

  return [hook, dimensions, element];
}