import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion'

import { HomeCard } from './HomeCard';
import { classNames } from '../utils';
import { Button } from './Button';
import { useTranslation } from 'next-i18next'

import { SignalIcon, PencilSquareIcon, CloudIcon, ChartBarSquareIcon } from '@heroicons/react/24/outline'
import Image from 'next/image';

const card1 = "/images/home/card1.mp4"
const card2 = "/images/home/card2.gif"
const card3 = "/images/home/card3.mp4"
const card4 = "/images/home/card3.png"

interface HomeCardsSectionProps {
}

interface LinesProps {
  selected: number
  isMobile: boolean
}

const OPTIONS = [
  { id: 1, name: "Connect", Icon: (props:any) => <SignalIcon {...props} /> },
  { id: 2, name: "Customize", Icon: (props:any) => <PencilSquareIcon {...props} />  },
  { id: 3, name: "Collect", Icon: (props:any) => <CloudIcon {...props} />  },
  { id: 4, name: "Monitor", Icon: (props:any) => <ChartBarSquareIcon {...props} />  },
]

const FADE_BOTTOM = {
  initial: {
    opacity: 0,
    bottom: - 400
  },
  final: {
    opacity: 1,
    bottom: 0
  }
}

export function HomeCardsSection({ }: HomeCardsSectionProps) {
  const [selected, setSelected] = useState<number>(1)
  const [isMobile, setIsMobile] = useState(false)
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      if (newWidth >= 550) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions)

  }, [])

  return (
    <motion.section className="mt-20 lg:mt-48 w-full mx-auto max-w-7xl dark:text-white text-neutral-600 "
      initial={FADE_BOTTOM.initial}
      whileInView={FADE_BOTTOM.final}
      transition={{
        duration: 0.3,
        delay: 0.2
      }}
      viewport={{ once: true }}
    >
      <motion.div className="w-full flex items-center justify-center"
        initial={FADE_BOTTOM.initial}
        whileInView={FADE_BOTTOM.final}
        transition={{
          duration: 0.4,
          delay: 0.3
        }}
        viewport={{ once: true }}
      >
        {/* <div className="w-full dark:h-[2px] h-[1px] bg-gradient-to-l from-neutral-500 to-background-900" ></div>
        <h3 className="font-light text-base md:text-lg dark:text-white text-gray-500 mx-2" >Deep<strong>Neuronic</strong></h3>
        <div className="w-full dark:h-[2px] h-[1px] bg-gradient-to-r from-neutral-500 to-background-900" ></div> */}
      </motion.div>
      <motion.h2
        initial={FADE_BOTTOM.initial}
        whileInView={FADE_BOTTOM.final}
        transition={{
          duration: 0.4,
          delay: 0.3
        }}
        viewport={{ once: true }}
        className="text-lg sm:text-2xl md:text-3xl font-bold tracking-tight  text-center">{t("home_cards_title")}
      </motion.h2>



      <div className="mt-2 flex justify-center md:gap-4 items-center z-10 p-0 sm:p-10 md:px-4 sm:pb-0 " >
        <div className="dark:bg-neutral-900  bg-white flex gap-2  rounded-xl " >
          {OPTIONS.map((item) => (
            <div key={item.id} className="relative " >
              <div className={classNames("relative rounded-xl z-0", selected === item.id ? "dark:bg-background-900/30 bg-primary-500 text-white" : "hover:text-primary-500")}>
                <div
                  onClick={() => setSelected(item.id)}
                  className={
                    classNames(
                      "w-20 h-16 sm:w-36 sm:h-24 flex items-center justify-center flex-col cursor-pointer",
                      "hover:shadow-orange-400 ",
                    )
                  }>
                  {/* <img className="w-6 md:w-7" src={item.icon} alt={item.name} /> */}
                  <item.Icon className="w-6 h-6 lg:w-7 lg:h-7" />
                  <span className=" text-sm sm:text-base mt-2" > {item.name} </span>
                </div>
              </div>

            </div>
          ))}
        </div>


      </div>


      <Lines isMobile={isMobile} selected={selected} />



      <AnimatePresence mode="wait">
        <HomeCard selected={selected} >

          <motion.div
            className="flex z-10 relative w-full h-full items-center justify-between "
            key={selected ?? "empty"}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >

            {selected === 1 && (
              <>
                <div className="hidden md:block w-1/2" >
                  <video src={card1} autoPlay loop muted />
                </div>
                <div className="w-full md:w-1/2 max-w-[470px] " >
                  <h3 className="text-lg md:text-xl lg:text-2xl font-bold" >{t("home_card1_title")}</h3>
                  <p className="mt-8 dark:text-gray-400 text-gray-600 " >{t("home_card1_description1")}</p>
                  <p className="mt-4 dark:text-gray-400 text-gray-600 " >{t("home_card1_description2")}</p>
                  <Button className="mt-8" >{t("Get started")}</Button>
                </div>
              </>
            )}

            {selected === 2 && (
              <>
                <div className="hidden md:block w-1/2" >
                  <Image width={536} height={301} src={card2} alt="" />
                  {/* <video src={card2} autoPlay loop muted /> */}
                </div>
                <div className="w-full md:w-1/2 max-w-[470px] " >
                  <h3 className=" text-lg md:text-xl lg:text-2xl font-bold" >{t("home_card2_title")}</h3>
                  <p className="mt-8 dark:text-gray-400 text-gray-600" >{t("home_card2_description1")}</p>
                  <p className="mt-4 dark:text-gray-400 text-gray-600" >{t("home_card2_description2")}</p>
                  <Button className="mt-8" >{t("Get started")}</Button>
                </div>
              </>
            )}

            {selected === 3 && (
              <>
                <div className="hidden md:block w-1/2" >
                  <video src={card3} autoPlay loop muted />
                </div>
                <div className="w-full md:w-1/2 max-w-[470px] " >
                  <h3 className=" text-lg md:text-xl lg:text-2xl font-bold" >{t("home_card3_title")}</h3>
                  <p className="mt-8 dark:text-gray-400 text-gray-600" >{t("home_card3_description1")}</p>
                  <p className="mt-4 dark:text-gray-400 text-gray-600" >{t("home_card3_description2")}</p>
                  <Button className="mt-8" >{t("Get started")}</Button>
                </div>
              </>
            )}

            {selected === 4 && (
              <>
                <div className="hidden md:block w-1/2" >
                  <Image width={536} height={301} src={card4} alt="card3" />
                </div>
                <div className="w-full md:w-1/2 max-w-[470px] " >
                  <h3 className=" text-lg md:text-xl lg:text-2xl font-bold" >{t("home_card4_title")}</h3>
                  <p className="mt-8 dark:text-gray-400 text-gray-600" >{t("home_card4_description1")}</p>
                  <p className="mt-4 dark:text-gray-400 text-gray-600" >{t("home_card4_description2")}</p>
                  <Button className="mt-8" >{t("Get started")}</Button>
                </div>
              </>
            )}

          </motion.div>
        </HomeCard>


      </AnimatePresence>



    </motion.section>
  );
}

export const Lines = ({ selected, isMobile }: LinesProps) => {
  return (
    <>
      {isMobile ? (
        <div className="flex justify-center gap-2 md:gap-4 items-center">
          <svg width="226" height="86" viewBox="0 0 226 86" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path d="M182 37.3209H182.001L218.712 37.6055C222.197 37.6055 225.023 34.0244 225.023 29.6065V-0.000541687" strokeWidth={.3} stroke={selected === 4 ? "url(#active)" : "url(#gray)"} />
            <path d="M7 49.5C3.51659 49.5 1 47.2985 1 44.6035L1 -0.000946045" strokeWidth={.3} stroke={selected === 1 ? "url(#active)" : "url(#gray)"} />
            <path d="M69.001 35.332C65.5175 35.332 62.8281 33.5157 62.8281 31.2295V0.146928" strokeWidth={.3} stroke={selected === 2 ? "url(#active)" : "url(#gray)"} />
            <path d="M182.279 37.1809H173.922C170.405 37.1809 167.555 39.9242 167.555 43.3085V85.9453" strokeWidth={.3} stroke={selected === 4 ? "url(#active)" : "url(#gray)"} />
            <path d="M7 49.5L80.8131 49.5255C85.0972 49.5255 88.5697 52.3277 88.5697 55.7848V85.9453" strokeWidth={.3} stroke={selected === 1 ? "url(#active)" : "url(#gray)"} />
            <path d="M68.6875 35.3281H96.8501C101.126 35.3281 104.591 39.75 104.591 45.2053V85.9453" strokeWidth={.3} stroke={selected === 2 ? "url(#active)" : "url(#gray)"} />
            <path d="M135.359 37.8008L156.928 37.7526C160.414 37.7526 163.239 34.1715 163.239 29.7535V0.146545" strokeWidth={.3} stroke={selected === 3 ? "url(#active)" : "url(#gray)"} />
            <path d="M135.365 37.8021H127.008C123.491 37.8021 120.641 40.5454 120.641 43.9297V85.9492" strokeWidth={.3} stroke={selected === 3 ? "url(#active)" : "url(#gray)"} />


            <path d="M7 49.5L80.8131 49.5255C85.0972 49.5255 88.5697 52.3277 88.5697 55.7848V85.9453" strokeWidth={4} stroke={selected === 1 ? "url(#gradientSelected)" : "url(#gradient)"} />
            <path d="M7 49.5C3.51659 49.5 1 47.2985 1 44.6035L1 -0.000946045" strokeWidth={4} stroke={selected === 1 ? "url(#gradientSelected)" : "url(#gradient)"} />
            <path d="M69.001 35.332C65.5175 35.332 62.8281 33.5157 62.8281 31.2295V0.146928" strokeWidth={4} stroke={selected === 2 ? "url(#gradient2Selected)" : "url(#gradient2)"} />
            <path d="M68.6875 35.3281H96.8501C101.126 35.3281 104.591 39.75 104.591 45.2053V85.9453" strokeWidth={4} stroke={selected === 2 ? "url(#gradient2Selected)" : "url(#gradient2)"} />
            <path d="M135.359 37.8008L156.928 37.7526C160.414 37.7526 163.239 34.1715 163.239 29.7535V0.146545" strokeWidth={4} stroke={selected === 3 ? "url(#gradient3Selected)" : "url(#gradient3)"} />
            <path d="M135.365 37.8021H127.008C123.491 37.8021 120.641 40.5454 120.641 43.9297V85.9492" strokeWidth={4} stroke={selected === 3 ? "url(#gradient3Selected)" : "url(#gradient3)"} />
            <path d="M182.279 37.1809H173.922C170.405 37.1809 167.555 39.9242 167.555 43.3085V85.9453" strokeWidth={4} stroke={selected === 4 ? "url(#gradient4Selected)" : "url(#gradient4)"} />
            <path d="M182 37.3209H182.001L218.712 37.6055C222.197 37.6055 225.023 34.0244 225.023 29.6065V-0.000541687" strokeWidth={4} stroke={selected === 4 ? "url(#gradient4Selected)" : "url(#gradient4)"} />

            <defs>
              <motion.linearGradient
                animate={{
                  x1: [0, 150],
                  x2: [20, 150],
                  y1: [0, 120],
                  y2: [10, 140]
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: 'linear'
                }} id="gradient"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2084F9" stopOpacity="0" />
                <stop offset="1" stopColor="#2084F9" />
                <stop stopColor="#f142b6" stopOpacity="0" />
              </motion.linearGradient>
              <motion.linearGradient
                animate={{
                  x1: [0, 190],
                  x2: [50, 220],
                  y1: [0, 170],
                  y2: [50, 200]
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: 'linear'
                }} id="gradientSelected"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2084F9" stopOpacity="0" />
                <stop offset="1" stopColor="#2084F9" />
                <stop stopColor="#f142b6" stopOpacity="0" />
              </motion.linearGradient>

              <motion.linearGradient
                animate={{
                  x1: [50, 230],
                  x2: [70, 250],
                  y1: [0, 160],
                  y2: [10, 170]
                }}
                transition={{
                  duration: 4.3,
                  repeat: Infinity,
                  ease: 'linear'
                }} id="gradient2"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2084F9" stopOpacity="0" />
                <stop offset="1" stopColor="#f142b6" />
                <stop stopColor="#f142b6" stopOpacity="0" />
              </motion.linearGradient>
              <motion.linearGradient
                animate={{
                  x1: [50, 200],
                  x2: [150, 260],
                  y1: [0, 170],
                  y2: [50, 200]
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  ease: 'linear'
                }} id="gradient2Selected"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2084F9" stopOpacity="0" />
                <stop offset="1" stopColor="#f142b6" />
                <stop stopColor="#f142b6" stopOpacity="0" />
              </motion.linearGradient>

              <motion.linearGradient
                animate={{
                  x1: [300, 200],
                  x2: [280, 200],
                  y1: [0, 160],
                  y2: [20, 170]
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: 'linear'
                }} id="gradient3"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#f6c02d" stopOpacity="0" />
                <stop offset="1" stopColor="#F69C2D" />
                <stop stopColor="#F69C2D" stopOpacity="0" />
              </motion.linearGradient>
              <motion.linearGradient
                animate={{
                  x1: [300, 200],
                  x2: [280, 200],
                  y1: [0, 160],
                  y2: [50, 200]
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  ease: 'linear'
                }} id="gradient3Selected"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#f6c02d" stopOpacity="0" />
                <stop offset="1" stopColor="#F69C2D" />
                <stop stopColor="#F69C2D" stopOpacity="0" />
              </motion.linearGradient>

              <motion.linearGradient
                animate={{
                  x1: [488, 320],
                  x2: [480, 320],
                  y1: [0, 160],
                  y2: [20, 170]
                }}
                transition={{
                  duration: 2.5,
                  repeat: Infinity,
                  ease: 'linear'
                }} id="gradient4"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#20b1f9" stopOpacity="0" />
                <stop offset="1" stopColor="#20b1f9" />
                <stop stopColor="#20b1f9" stopOpacity="0" />
              </motion.linearGradient>
              <motion.linearGradient
                animate={{
                  x1: [488, 320],
                  x2: [460, 320],
                  y1: [0, 160],
                  y2: [50, 200]
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  ease: 'linear'
                }} id="gradient4Selected"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#20b1f9" stopOpacity="0" />
                <stop offset="1" stopColor="#20b1f9" />
                <stop stopColor="#20b1f9" stopOpacity="0" />
              </motion.linearGradient>

              <linearGradient id="gray" x1="0" x2="1" y1="1" y2="0">
                <stop id="stop1" stopColor="#777777" offset="0%"></stop>
                <stop id="stop2" stopColor="#777777" offset="100%"></stop>
              </linearGradient>

              <linearGradient id="active" x1="0" x2="1" y1="1" y2="0">
                <stop id="stop1" stopColor="#2084F9" offset="0%"></stop>
                <stop id="stop2" stopColor="#2084F9" offset="100%"></stop>
              </linearGradient>
            </defs>
          </svg>


        </div>
      ) : (
        <>
          <div className="flex justify-center gap-2 md:gap-4 items-center">

            <svg width="488" height="139" viewBox="0 0 488 139" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M357.836 60L476.777 60.9219C482.424 60.9219 487.001 55.1205 487.001 47.9634V0" strokeWidth={.3} stroke={selected === 4 ? "url(#active)" : "url(#gray)"} />
              <path d="M11 79.9766C5.35687 79.9766 1 76.6253 1 72.2594L1 0" strokeWidth={.3} stroke={selected === 1 ? "url(#active)" : "url(#gray)"} />
              <path d="M174.336 57C168.693 57 164.336 54.0576 164.336 50.3539V0" strokeWidth={.3} stroke={selected === 2 ? "url(#active)" : "url(#gray)"} />
              <path d="M357.854 60.0015H344.315C338.618 60.0015 334 64.4456 334 69.9282V139" strokeWidth={.3} stroke={selected === 4 ? "url(#active)" : "url(#gray)"} />
              <path d="M10.8359 80L193.492 79.9997C200.433 79.9997 206.058 84.5393 206.058 90.1399V139" strokeWidth={.3} stroke={selected === 1 ? "url(#active)" : "url(#gray)"} />
              <path d="M173.836 57H219.459C226.386 57 232 64.1635 232 73.001V139" strokeWidth={.3} stroke={selected === 2 ? "url(#active)" : "url(#gray)"} />
              <path d="M281.836 61L316.777 60.9219C322.424 60.9219 327.001 55.1205 327.001 47.9634V0" strokeWidth={.3} stroke={selected === 3 ? "url(#active)" : "url(#gray)"} />

              <path d="M281.854 61.0015H268.315C262.618 61.0015 258 65.4456 258 70.9282V139" strokeWidth={.3} stroke={selected === 3 ? "url(#active)" : "url(#gray)"} />


              <path d="M10.8359 80L193.492 79.9997C200.433 79.9997 206.058 84.5393 206.058 90.1399V139" strokeWidth={4} stroke={selected === 1 ? "url(#gradientSelected)" : "url(#gradient)"} />
              <path d="M11 79.9766C5.35687 79.9766 1 76.6253 1 72.2594L1 0" strokeWidth={4} stroke={selected === 1 ? "url(#gradientSelected)" : "url(#gradient)"} />
              <path d="M174.336 57C168.693 57 164.336 54.0576 164.336 50.3539V0" strokeWidth={4} stroke={selected === 2 ? "url(#gradient2Selected)" : "url(#gradient2)"} />
              <path d="M173.836 57H219.459C226.386 57 232 64.1635 232 73.001V139" strokeWidth={4} stroke={selected === 2 ? "url(#gradient2Selected)" : "url(#gradient2)"} />
              <path d="M281.836 61L316.777 60.9219C322.424 60.9219 327.001 55.1205 327.001 47.9634V0" strokeWidth={4} stroke={selected === 3 ? "url(#gradient3Selected)" : "url(#gradient3)"} />
              <path d="M281.854 61.0015H268.315C262.618 61.0015 258 65.4456 258 70.9282V139" strokeWidth={4} stroke={selected === 3 ? "url(#gradient3Selected)" : "url(#gradient3)"} />
              <path d="M357.854 60.0015H344.315C338.618 60.0015 334 64.4456 334 69.9282V139" strokeWidth={4} stroke={selected === 4 ? "url(#gradient4Selected)" : "url(#gradient4)"} />
              <path d="M357.836 60L476.777 60.9219C482.424 60.9219 487.001 55.1205 487.001 47.9634V0" strokeWidth={4} stroke={selected === 4 ? "url(#gradient4Selected)" : "url(#gradient4)"} />

              <defs>
                <motion.linearGradient
                  animate={{
                    x1: [0, 200],
                    x2: [20, 200],
                    y1: [0, 160],
                    y2: [10, 170]
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    ease: 'linear'
                  }} id="gradient"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#2084F9" stopOpacity="0" />
                  <stop offset="1" stopColor="#2084F9" />
                  <stop stopColor="#f142b6" stopOpacity="0" />
                </motion.linearGradient>
                <motion.linearGradient
                  animate={{
                    x1: [0, 190],
                    x2: [50, 220],
                    y1: [0, 170],
                    y2: [50, 200]
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: 'linear'
                  }} id="gradientSelected"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#2084F9" stopOpacity="0" />
                  <stop offset="1" stopColor="#2084F9" />
                  <stop stopColor="#f142b6" stopOpacity="0" />
                </motion.linearGradient>

                <motion.linearGradient
                  animate={{
                    x1: [50, 230],
                    x2: [70, 250],
                    y1: [0, 160],
                    y2: [10, 170]
                  }}
                  transition={{
                    duration: 4.3,
                    repeat: Infinity,
                    ease: 'linear'
                  }} id="gradient2"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#2084F9" stopOpacity="0" />
                  <stop offset="1" stopColor="#f142b6" />
                  <stop stopColor="#f142b6" stopOpacity="0" />
                </motion.linearGradient>
                <motion.linearGradient
                  animate={{
                    x1: [50, 200],
                    x2: [150, 260],
                    y1: [0, 170],
                    y2: [50, 200]
                  }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: 'linear'
                  }} id="gradient2Selected"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#2084F9" stopOpacity="0" />
                  <stop offset="1" stopColor="#f142b6" />
                  <stop stopColor="#f142b6" stopOpacity="0" />
                </motion.linearGradient>

                <motion.linearGradient
                  animate={{
                    x1: [300, 200],
                    x2: [280, 200],
                    y1: [0, 160],
                    y2: [20, 170]
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    ease: 'linear'
                  }} id="gradient3"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#f6c02d" stopOpacity="0" />
                  <stop offset="1" stopColor="#F69C2D" />
                  <stop stopColor="#F69C2D" stopOpacity="0" />
                </motion.linearGradient>
                <motion.linearGradient
                  animate={{
                    x1: [300, 200],
                    x2: [280, 200],
                    y1: [0, 160],
                    y2: [50, 200]
                  }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: 'linear'
                  }} id="gradient3Selected"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#f6c02d" stopOpacity="0" />
                  <stop offset="1" stopColor="#F69C2D" />
                  <stop stopColor="#F69C2D" stopOpacity="0" />
                </motion.linearGradient>

                <motion.linearGradient
                  animate={{
                    x1: [488, 320],
                    x2: [480, 320],
                    y1: [0, 160],
                    y2: [20, 170]
                  }}
                  transition={{
                    duration: 2.5,
                    repeat: Infinity,
                    ease: 'linear'
                  }} id="gradient4"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#20b1f9" stopOpacity="0" />
                  <stop offset="1" stopColor="#20b1f9" />
                  <stop stopColor="#20b1f9" stopOpacity="0" />
                </motion.linearGradient>
                <motion.linearGradient
                  animate={{
                    x1: [488, 320],
                    x2: [460, 320],
                    y1: [0, 160],
                    y2: [50, 200]
                  }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: 'linear'
                  }} id="gradient4Selected"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#20b1f9" stopOpacity="0" />
                  <stop offset="1" stopColor="#20b1f9" />
                  <stop stopColor="#20b1f9" stopOpacity="0" />
                </motion.linearGradient>

                <linearGradient id="gray" x1="0" x2="1" y1="1" y2="0">
                  <stop id="stop1" stopColor="#777777" offset="0%"></stop>
                  <stop id="stop2" stopColor="#777777" offset="100%"></stop>
                </linearGradient>

                <linearGradient id="active" x1="0" x2="1" y1="1" y2="0">
                  <stop id="stop1" stopColor="#2084F9" offset="0%"></stop>
                  <stop id="stop2" stopColor="#2084F9" offset="100%"></stop>
                </linearGradient>
              </defs>
            </svg>


          </div>
        </>
      )}

    </>
  )
}
