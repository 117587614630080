/**
 * Component that renders dropdown content based on @option properties
 */
//-----------------------------------------------------------------------------

import { useContext } from "react";
import { Context } from "./DropdownProvider";
import { motion } from "framer-motion";
//-----------------------------------------------------------------------------

export function DropdownSection({ option }: any) {
  const { cachedId } = useContext(Context);
  const { id, contentDimensions, optionCenterX, offset } = option;

  const contentWidth = contentDimensions?.width || 0;
  const x = optionCenterX - contentWidth / 2 + (offset ?? 0);

  const isActive = cachedId === id;

  return (
    <motion.div
      className="dropdown-section"
      animate={{
        x,
        opacity: isActive ? 1 : 0,
        pointerEvents: isActive ? "unset" : "none",
      }}
      initial={{ x }}
      transition={{
        ease: "easeOut",
        opacity: { duration: 0.1 },
      }}
    >
      <option.WrappedContent />
    </motion.div>
  );
}