// components/WhatsAppContact.js
import React from 'react';
import { useTranslation } from 'next-i18next';
import { FaWhatsapp } from 'react-icons/fa';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { classNames } from '@/utils';

const gabrielaImage = '/images/gabriela_circled.jpeg';
const vitorImage = '/images/vitor_circled.png';

interface WhatsAppContactProps {
  contactName: string;
  contactNumber: string
  avatar: string
  role:string
}

const WhatsAppContact = ({contactName, contactNumber, avatar, role}:WhatsAppContactProps) => {
  const { t, i18n } = useTranslation("common");
  const router = useRouter();

  const phoneNumber = contactNumber; // Replace with your WhatsApp phone number
  const message = router.locale === "en"
    ? "Hello, I would like to know more about DeepNeuronic's computer vision business."
    : 'Olá, gostaria de saber mais sobre os serviços da DeepNeuronic';

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div
      className="flex w-full max-w-[720px] items-center justify-center  gap-4"
    >
      <div className="w-[60px] h-[60px] rounded-full  border-black border overflow-hidden " >
        <Image width={60} height={60} src={avatar} alt="" />
      </div>
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(
          "flex items-center cursor-pointer justify-center h-14 max-w-[200px] w-full px-4 py-2 border-2 border-gray-500 rounded-2xl transition duration-300",
          " bg-white/90 dark:bg-neutral-800/60 pr-6"
        )}
      >
        <FaWhatsapp size={30} className="mr-2 text-green-500" />
        <div className="w-full text-white text-sm " >
          <p className="dark:text-neutral-300 text-neutral-700 ml-1" >{contactName}</p>
          <div className="h-[1px] w-[100%] bg-neutral-300" ></div>
          <p className="dark:text-neutral-400 text-neutral-500 ml-1" >{role}</p>
        </div>
      </a>
    </div>

  );
};

export default WhatsAppContact;
