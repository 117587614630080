import { useEffect, useState } from 'react';
import { Button } from './Button';
import { IndustriesType } from '../resources/constants';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { classNames } from '../utils';
import Image from 'next/image';

interface IndustryVideoSectionProps {
  industry?: IndustriesType
}

export function IndustryVideoSection({ industry }: IndustryVideoSectionProps) {
  const [activeTab, setActiveTab] = useState<any>(industry?.examples[0]);
  const [render, setRender] = useState(false);
  const router = useRouter();
  const { asPath: pathname } = router;
  const { t } = useTranslation();

  useEffect(() => {
    if (render)
      setRender(false)
  }, [industry, pathname]);

  useEffect(() => {
    if (!render) {
      setRender(true);
      setActiveTab(industry?.examples[0]);
    }
  }, [render]);

  return (
    <div className="w-full lg:h-[700px] p-4 md:p-12 lg:py-24 dark:text-white text-neutral-600  relative  md:rounded-3xl dark:bg-background-900 bg-[#eef0f3]" >
      <div className="relative flex flex-col lg:flex-row items-center gap-4 lg:gap-8 mx-auto max-w-7xl w-full overflow-hidden min-h-[420px] p-10 rounded-3xl  " >
        {/* <div className=" md:max-w-[600px] md:max-h-[400px] rounded-lg" > */}
        {/* @ts-ignore */}
        {render && (
          <div className="w-full hidden lg:block" >
            {activeTab.image ? (
              <Image
                width={700}
                height={399}
                className=" w-full rounded-lg object-contain"
                src={activeTab.image ?? ""}
                alt={activeTab.name}
              />
            ) : (
              <video
                autoPlay
                loop
                className=" w-full rounded-lg object-contain"
                src={activeTab.video}></video>
            )}

          </div>
        )}


        {/* </div> */}

        {render && (
          <Tabs
            animate={{
              initial: { y: 200 },
              mount: { y: 0, transition: { duration: 0.100 } },
              unmount: { y: 200, transition: { duration: 0.100 } },

            }} id="custom-animation" value={industry?.examples[0].id}>
            <TabsHeader
              className="bg-transparent items-center flex"
              indicatorProps={{
                className: "bg-neutral-100 shadow-none text-blue-900 rounded-2xl border border-neutral-600",
              }}
            >
              {industry && industry.examples.map((item, index) => (
                <Tab key={`${item.name}`} value={item.id}
                  className={classNames(
                    activeTab.id === item.id ? "text-neutral-900 h-[48px] text-xs md:text-sm " : "h-[48px] text-xs md:text-sm",
                    "flex items-center justify-center delay-200 text-primary-500 "
                  )}
                  onClick={() => setActiveTab(item)}
                >
                  {t(`${item.name}`)}
                </Tab>
              ))}
            </TabsHeader>

            <TabsBody className="w-full" >
              {industry && industry.examples.map((item, index) => (
                <TabPanel key={index} value={item.id}>
                  <div className="lg:flex justify-between gap-10 w-full" >

                    <div className="w-full mt-2 block lg:hidden" >
                      {item.image ? (
                        <Image
                          width={700}
                          height={399}
                          className=" w-full rounded-lg object-contain"
                          src={activeTab.image ?? ""}
                          alt={activeTab.name}
                        />
                      ) : (
                        <video
                          autoPlay
                          loop
                          className=" w-full rounded-lg object-contain"
                          src={item.video}></video>
                      )}

                    </div>


                    <div className="md:flex-1 min-h-[370px] h-full md:w-full lg:w-[620px] flex flex-col justify-between" >
                      <div className="mt-12" >
                        <p className="text-lg font-semibold" >
                          {t(`${item.title}`)}
                          {/* Monitor traffic flow and detect potential safety concerns in real-time with our advanced computer vision technology. */}
                        </p>
                        <p className="mt-2 text-sm dark:text-gray-400 text-gray-600" >
                          {t(`${item.description}`)}
                          {/* Take action to alleviate traffic and improve safety, by monitoring: */}
                        </p>
                      </div>

                      <div className="mt-2 text-sm dark:text-gray-200 text-neutral-900" >
                        <ul>
                          {item.includes.map((i) => (
                            <li key={i}>
                              {t(`${i}`)}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="w-full flex justify-center lg:justify-end mt-4 lg:mt-0" >

                        <Button>{t("cta")}</Button>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              ))}
            </TabsBody>

          </Tabs>
        )}


      </div>
    </div>
  );
}
