import { ReactNode, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { classNames } from '../utils';

interface ImageDisplayProps {
  children: ReactNode[];
  duration?: number; // The duration for which each image will be displayed (in seconds)
  className?: any
}

export function ImageDisplay({ children, duration = 6, className }: ImageDisplayProps) {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % children.length);
    }, duration * 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [children, duration]);

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
      className={
        classNames(
          "max-w-[600px] w-full",
          className
          )
      }
        key={currentImage}
        variants={fadeIn}
        initial="hidden"
        animate="visible"
        exit="hidden"
        transition={{ duration: 0.5 }}
      >
        {children[currentImage]}
      </motion.div>
    </AnimatePresence>
  );
}
