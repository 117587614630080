export const DELIMITED_AREA = [

  {
    "ID": 0,
    "EVENT_TYPE": "",
    "TARGET_CLASS_NAME": "Road-1 • CLASS",
    "POINTS": [
      [
        117.94,
        299.93
      ],
      [
        113.97,
        343.69
      ],
      [
        102.03,
        383.47
      ],
      [
        70.21,
        425.24
      ],
      [
        4.57,
        492.86
      ],
      [
        0,
        662.92
      ],
      [
        131.87,
        468.99
      ],
      [
        167.67,
        403.36
      ],
      [
        177.61,
        347.67
      ],
      [
        173.64,
        295.96
      ]
    ],
    "COLOUR": [
      128,
      219,
      242,
      1
    ],
    "hexColor": "#80DBF2" // Hex for RGB(128, 219, 242)
  },
  {
    "ID": 1,
    "EVENT_TYPE": "",
    "TARGET_CLASS_NAME": "Road-2 • CLASS",
    "POINTS": [
      [
        185.57,
        293.97
      ],
      [
        533.04,
        720
      ],
      [
        795.78,
        720
      ],
      [
        266.32,
        285.02
      ]
    ],
    "COLOUR": [
      237,
      140,
      242,
      1
    ],
    "hexColor": "#ED8CF2" // Hex for RGB(237, 140, 242)
  },
  {
    "ID": 2,
    "EVENT_TYPE": "",
    "TARGET_CLASS_NAME": "Road-3 • CLASS",
    "POINTS": [
      [
        392.62,
        353.64
      ],
      [
        449.3,
        342.7
      ],
      [
        1104.27,
        720
      ],
      [
        898.21,
        720
      ]
    ],
    "COLOUR": [
      86,
      191,
      64,
      1
    ],
    "hexColor": "#56BF40" // Hex for RGB(86, 191, 64)
  }
]