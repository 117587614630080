//@ts-nocheck
/**
 * Utility functions for formatting data
 */
import { ObjectType, VideoPredictionsType } from "../types/PredictionType";
import { getColorByClass } from "./classes";

type PredictionsResponse = typeof JSON

const calculateIoU = (box1: any, box2: any) => {
  const [x_box1, y_box1, width_box1, height_box1] = box1;
  const [x_box2, y_box2, width_box2, height_box2] = box2;

  const x1_inter = Math.max(x_box1, x_box2);
  const y1_inter = Math.max(y_box1, y_box2);
  const x2_inter = Math.min(x_box1 + width_box1, x_box2 + width_box2);
  const y2_inter = Math.min(y_box1 + height_box1, y_box2 + height_box2);

  const width_inter = Math.max(0, x2_inter - x1_inter);
  const height_inter = Math.max(0, y2_inter - y1_inter);
  const area_inter = width_inter * height_inter;

  const area_box1 = width_box1 * height_box1;
  const area_box2 = width_box2 * height_box2;

  const area_union = area_box1 + area_box2 - area_inter;

  const IoU = area_inter / area_union;

  return IoU;
};

export const handleFormatImageData = (data: any) => {
  const predictionsMap = new Map();
  const iouThreshold = 0.5;

  for (const category in data) {
    Object.entries(data[category]).forEach(([name, boundingBox]) => {
      const parts = name.split('_');

      const obj = {
        class: parts[0],
        id: `${parts[1]}`,
        confidence: parts[2],
        boundingBox,
      };

      let isUpdated = false;

      // Compare the bounding boxes of the predictions in the Map with the current obj bounding box
      predictionsMap.forEach((prediction: any, key: any) => {
        const iou = calculateIoU(prediction.boundingBox, obj.boundingBox);

        if (iou >= iouThreshold) {
          // If the IoU is above the threshold, update the existing object with the new class and confidence
          prediction.classes.push({ class: obj.class, confidence: obj.confidence });
          predictionsMap.set(key, prediction);
          isUpdated = true;
        }
      });

      if (!isUpdated) {
        // If the object was not updated, create a new object and add it to the map
        predictionsMap.set(obj.id, {
          name,
          id: obj.id,
          classes: [{ class: obj.class, confidence: obj.confidence }],
          boundingBox,
        });
      }
    });
  }

  // Convert the Map to an array
  return Array.from(predictionsMap.values());
};

// export const handleFormatImageData = (data: any) => {
//   const predictions = [];

//   for (const category in data) {
//     Object.entries(data[category]).forEach(([name, boundingBox]) => {
//       const parts = name.split('_');

//       let obj;

//       obj = {
//         class: parts[0],
//         id: `${parts[1]}`,
//         confidence: parts[2],
//       };

//       predictions.push({
//         name,
//         id: obj.id,
//         class: obj.class,
//         confidence: obj.confidence,
//         boundingBox,
//       });
//     });
//   }
//   return predictions;
// };

// export const handleFormatVideoData = (json: any[]) => {
//   const formattedFrames = {} as any;

//   for (const frame in json) {
//     const frameData = json[frame];
//     formattedFrames[frame] = { objects: [] };

//     for (const name in frameData) {
//       const boundingBox = frameData[name];
//       const parts = name.split('_');

//       const obj = {
//         class: parts[0],
//         id: `${parts[0].charAt(0).toLocaleUpperCase()}${parts[1]}`,
//         confidence: parts[2],
//       };

//       formattedFrames[frame].objects.push({
//         name,
//         id: obj.id,
//         class: obj.class,
//         confidence: obj.confidence,
//         boundingBox,
//         counting: parseInt(parts[1], 10),
//       });
//     }
//   }

//   return Object.values(formattedFrames);
// };

export const handleFormatVideoData = (json: any[]) => {
  const formattedFrames = {} as any;

  for (const frame in json) {
    const frameData = json[frame];
    formattedFrames[frame] = { objects: [] };

    for (const name in frameData) {
      const boundingBox = frameData[name];
      const parts = name.split('_');

      const obj = {
        class: parts[0],
        id: `${parts[0][0]}${parts[1]}`,
        confidence: parts[2],
      };

      let existingObject = null;

      for (const object of formattedFrames[frame].objects) {
        const iou = calculateIoU(object.boundingBox, boundingBox);

        if (iou > 0.5) {
          existingObject = object;
          break;
        }
      }

      if (existingObject) {
        existingObject.classes.push({
          class: obj.class,
          confidence: obj.confidence,
        });
      } else {
        formattedFrames[frame].objects.push({
          name: `${parts[0]}_${parts[1]}`,
          id: obj.id,
          boundingBox,
          classes: [{ class: obj.class, confidence: obj.confidence }],
        });
      }
    }
  }

  return Object.values(formattedFrames);
};

export function handleFormatVideoPredictions(json: PredictionsResponse) {
  const formattedFrames = {};

  for (const model in json) {
    for (const frame in json[model]) {
      if (!formattedFrames[frame]) {
        formattedFrames[frame] = { objects: [] };
      }

      json[model][frame].forEach(obj => {
        const objectKey = Object.keys(obj)[0];
        formattedFrames[frame].objects.push({
          id: `${obj[objectKey].class.charAt(0).toLocaleUpperCase()}${obj[objectKey].id}`,
          name: `${obj[objectKey].class}_${obj[objectKey].id}`,
          confidence: obj[objectKey].confidence,
          class: obj[objectKey].class,
          boundingBox: obj[objectKey].bbox,
          counting: obj[objectKey].id
        });
      });
    }
  }
  return Object.values(formattedFrames);
}

//This function is specific for the parking lot sample
export const handleFormatVideoDataWithParkingStatus = (json: any) => {
  const videoName = Object.keys(json)[0];
  const videoDataFrames = json[videoName][0];
  const formattedFrames = {} as any;

  for (const frame in videoDataFrames) {
      const frameData = videoDataFrames[frame];
      formattedFrames[frame] = { objects: [] };

      for (const name in frameData) {
          const boundingBox = frameData[name];

          // Ignore the bounding box if it is [-1, -1, -1, -1]
          if (JSON.stringify(boundingBox) === JSON.stringify([-1, -1, -1, -1])) {
              continue;
          }
          
          const parts = name.split(" \u2022 ");
          let vehicleName;
          let vehicleConfidence;
          let status;
          let objId;

          if (parts.length < 2) {
              const subparts = parts[0].split("_");
              vehicleName = subparts[0];
              vehicleConfidence = subparts[2];
              status = null;
              objId = subparts[1];
          } else {
              status = parts[0];
              const subparts = parts[1].split("_");
              vehicleName = subparts[0];
              vehicleConfidence = subparts[2];
              objId = subparts[1];
          }

          const obj = {
              class: vehicleName,
              id: `${vehicleName}_${objId}`,
              confidence: vehicleConfidence,
          };

          let existingObject = null;

          for (const object of formattedFrames[frame].objects) {
              const iou = calculateIoU(object.boundingBox, boundingBox);

              if (iou > 0.5) {
                  existingObject = object;
                  break;
              }
          }

          if (existingObject) {
              existingObject.classes.push({
                  class: obj.class,
                  confidence: obj.confidence,
              });
          } else {
              formattedFrames[frame].objects.push({
                  name: `${obj.class}_${frame}`,
                  id: obj.id,
                  boundingBox,
                  classes: [{ class: obj.class, confidence: obj.confidence }],
              });
          }
      }
  }

  return Object.values(formattedFrames);
}

// export const organizeByClass = (predictions: any[]) => {
//   const organizedPredictions: { [key: string]: any[] } = {};

//   predictions.forEach((prediction) => {
//     const { class: predictionClass } = prediction;

//     if (!organizedPredictions[predictionClass]) {
//       organizedPredictions[predictionClass] = [];
//     }

//     organizedPredictions[predictionClass].push(prediction);
//   });

//   const orderedPredictions = Object.entries(organizedPredictions)
//     .sort(([classA], [classB]) => classA.localeCompare(classB))
//     .map(([predictionClass, predictions]) => ({
//       class: predictionClass,
//       predictions,
//     }));

//   return orderedPredictions;
// };

export const organizeByClass = (predictions: ObjectType[]) => {
  const organizedPredictions: { [key: string]: any[] } = {};

  predictions.forEach((prediction) => {
    prediction.classes.forEach(({ class: predictionClass, confidence }) => {
      if (!organizedPredictions[predictionClass]) {
        organizedPredictions[predictionClass] = [];
      }

      organizedPredictions[predictionClass].push({
        ...prediction,
        class: predictionClass,
        confidence: confidence,
      });
    });
  });

  return Object.entries(organizedPredictions).map(([predictionClass, predictions]) => ({
    class: predictionClass,
    predictions,
  }));
};


type MaskDataConfig = {
  enableCounting: boolean
  enableTracking: boolean

}

export const convertJsonToMaskData = (json: VideoPredictionsType, config?: MaskDataConfig) => {
  let maskData = [] as any;
  let frameCount = 0;

  json.forEach((data) => {
    const { objects } = data;
    let elements = [] as any;

    objects.forEach((obj) => {
      const { boundingBox, classes, id } = obj;
      const [x, y, w, h] = boundingBox;

      // Get classNames from the classes array
      const classNames = classes.map(({ class: className }) => className);

      elements.push({
        type: 'div',
        x,
        y,
        width: w,
        height: h,
        color: getColorByClass(classNames[0]), // Use the first className for the color
        label: [classNames[0]], // Store classNames array as the label
        id
      });
    });

    maskData.push({
      frame: frameCount,
      elements,
    });

    frameCount++;
  });

  return maskData;
};

export const convertJsonToImageMaskData = (objects: any[], config?: MaskDataConfig) => {
  let elements = [] as any;

  objects.forEach((obj) => {
    const { boundingBox, classes } = obj;
    const [x, y, w, h] = boundingBox;

    // Get classNames from the classes array
    const classNames = classes.map(({ class: className }) => className);

    elements.push({
      x,
      y,
      width: w,
      height: h,
      color: getColorByClass(classNames[0]), 
      label: [classNames[0]]
    });
  });

  return elements
};


/**
 * Help function to format class names 
 * @param classes any
 * @returns Formatted className
 */
export function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

