import { ReactNode, useEffect, useState } from 'react';
import { motion } from 'framer-motion'


import { useScroll, useTransform } from 'framer-motion';
import { classNames } from '../utils';
import { useTranslation } from 'next-i18next';

import { Carousel } from '@material-tailwind/react';
import { Button } from './Button';
import { ImageDisplay } from './ImageDisplay';
import { useRouter } from 'next/router';
import Image from 'next/image';
// import { ImageDisplay } from './ImageDisplay';

const feature1 = "/images/home/feature_1.png"
const feature2 = "/images/home/feature2.mp4"
const feature3 = "/images/home/feature_3.png"

interface HomeDiscoverSectionProps {
}
const FADE_LEFT = {
  initial: {
    opacity: 0,
    left: - 400
  },
  final: {
    opacity: 1,
    left: 0
  }
}

const FADE_RIGHT = {
  initial: {
    opacity: 0,
    right: - 400
  },
  final: {
    opacity: 1,
    right: 0
  }
}

const FADE_BOTTOM = {
  initial: {
    opacity: 0,
    bottom: - 400
  },
  final: {
    opacity: 1,
    bottom: 0
  }
}

const ANIMATE_ONCE = true

export function HomeDiscoverSection({ }: HomeDiscoverSectionProps) {
  const { t, i18n } = useTranslation("common");
  const router = useRouter()


  const handleNavigate = () => {
    const currentLocale = router.locale;
    router.push(`/solutions`, undefined, { locale: currentLocale });
  }

  return (

    <section className="mt-24 relative lg:mt-24 w-full mx-auto max-w-7xl dark:text-white text-neutral-600 " >
      <motion.div
        className="w-full px-4 xl:px-0 flex relative items-center z-10"
        transition={{
          duration: 0.3,
          delay: 0.3
        }}
        viewport={{ once: true }}
      >
        <motion.div
          initial={{
            height: 0
          }}
          whileInView={{
            height: 96
          }}
          transition={{
            duration: .6,
            delay: 0.1
          }}
          viewport={{ once: true }}
          className="bg-gradient-to-b from-primary-600 to-cyan-500  relative w-1 h-24"
        />

        <div className="ml-4" >
          <motion.h2
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.3,
              delay: 0.3
            }}
            viewport={{ once: true }}
            className="text-2xl sm:text-3xl font-bold tracking-tight " >{t("home_grid_title")}
          </motion.h2>
        </div>

      </motion.div>

      <div className="absolute transform-gpu z-0 top-20 -left-[5%] blur-3xl opacity-50" >
        <svg id="visual" viewBox="0 0 300 300" width="300" height="300"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
          <g transform="translate(164.26754773775951 120.47676207858599)">
            <path d="M27.8 -58.9C35.9 -43.4 42.3 -35.8 61.7 -27.3C81.1 -18.8 113.6 -9.4 120.5 4C127.4 17.3 108.7 34.7 97.7 57.8C86.7 80.9 83.4 109.7 68.3 134.9C53.3 160 26.7 181.5 2.4 177.3C-21.8 173.1 -43.7 143.3 -76.1 128.2C-108.6 113.1 -151.7 112.8 -150.5 93.9C-149.2 75 -103.6 37.5 -88 9C-72.4 -19.5 -86.9 -39 -89 -59.4C-91 -79.7 -80.8 -100.9 -63.9 -111.3C-47 -121.7 -23.5 -121.4 -6.8 -109.5C9.8 -97.7 19.7 -74.4 27.8 -58.9" fill="#2084F9"></path>
          </g>
        </svg>
      </div>

      <div className="absolute transform-gpu z-0 top-60 left-[5%] blur-3xl opacity-50" >
        <svg id="visual2" viewBox="0 0 900 600" width="900" height="600"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
          <g transform="translate(443.57423807724894 308.9541724911854)">
            <path d="M79.8 -153.2C109.2 -121.2 142.9 -111.6 177.4 -89.8C211.8 -68 246.9 -34 228.3 -10.7C209.7 12.5 137.3 25 115.3 68.3C93.3 111.6 121.7 185.7 110.8 210C100 234.2 50 208.6 5.3 199.5C-39.5 190.4 -79 197.8 -90 173.7C-100.9 149.5 -83.4 93.7 -96.5 59.5C-109.5 25.3 -153.3 12.7 -184 -17.7C-214.8 -48.2 -232.5 -96.3 -211.4 -116.5C-190.2 -136.7 -130.1 -129 -88.4 -153.9C-46.7 -178.8 -23.3 -236.4 0.9 -238C25.2 -239.6 50.3 -185.2 79.8 -153.2" fill="#C55691"></path>
          </g>
        </svg>
      </div>

      <div className="absolute transform-gpu z-0 top-[460px] -left-[5%] blur-3xl opacity-50" >
        <svg id="visual3" viewBox="0 0 300 300" width="300" height="300"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
          <g transform="translate(164.26754773775951 120.47676207858599)">
            <path d="M27.8 -58.9C35.9 -43.4 42.3 -35.8 61.7 -27.3C81.1 -18.8 113.6 -9.4 120.5 4C127.4 17.3 108.7 34.7 97.7 57.8C86.7 80.9 83.4 109.7 68.3 134.9C53.3 160 26.7 181.5 2.4 177.3C-21.8 173.1 -43.7 143.3 -76.1 128.2C-108.6 113.1 -151.7 112.8 -150.5 93.9C-149.2 75 -103.6 37.5 -88 9C-72.4 -19.5 -86.9 -39 -89 -59.4C-91 -79.7 -80.8 -100.9 -63.9 -111.3C-47 -121.7 -23.5 -121.4 -6.8 -109.5C9.8 -97.7 19.7 -74.4 27.8 -58.9" fill="#06b6d4"></path>
          </g>
        </svg>
      </div>



      <div className="relative z-10 mt-16 flex flex-col lg:flex-row gap-10 items-center" >

        <div className=" glowCardBorder relative text-md lg:w-[90%] py-8 px-8 rounded-xl   bg-white/70 dark:bg-neutral-900/50">
          {/* Feature 1 */}
          <div>
            <h2 className="text-lg sm:text-xl  font-bold tracking-tight ">{t("Traffic analysis")}</h2>
            <p className=" tracking-tight mt-1 ">{t("home_grid_item1")}</p>
            {/* <p className=" tracking-tight ">{t("home_grid_item1.2")} </p> */}
            <ul className="font-semibold tracking-tight dark:text-neutral-200 text-gray-600 list-disc mt-2 pl-10">
              <li>{t("Detect congestion")}</li>
              <li>{t("Identify accidents")}</li>
            </ul>

          </div>

          {/* Feature 2 */}
          <div className="mt-8">
            <h2 className="text-lg sm:text-xl font-bold tracking-tight ">{t("Crowd detection")}</h2>
            {/* <p className=" tracking-tight mt-3 ">{t("home_grid_item2")}</p>   */}
            <p className=" tracking-tight mt-1 ">{t("home_grid_item2.2")}</p>
            <ul className="font-semibold tracking-tight dark:text-neutral-200 text-gray-600 list-disc mt-2 pl-10">
              <li>{t("Monitor indoor/outdoor crowds")}</li>
              <li>{t("Ensure social distancing")}</li>
              <li>{t("Count and classify people")}</li>
            </ul>

            {/* <Button className="mt-10 ml-10" >View more</Button> */}
          </div>

          {/* Feature 3 */}
          <div className="mt-8">
            <h2 className="text-lg sm:text-xl font-bold tracking-tight ">{t("Public safety")}</h2>
            <p className=" tracking-tight mt-1 ">{t("Instant risk detection:")}</p>
            <ul className="font-semibold tracking-tight dark:text-neutral-200 text-gray-600 list-disc mt-2 pl-10">
              <li>{t("Identify threats")}</li>
              <li>{t("Abandoned items")}</li>
              <li>{t("Violence")}</li>
            </ul>


            <Button onClick={handleNavigate} className="mt-10" >{t("Explore more")}</Button>
          </div>
        </div>

        <ImageDisplay className="hidden lg:block">
          <motion.div
          >
            <Image
              width={600}
              height={420}
              src={feature1}
              alt="image 1"
              className="h-full w-full object-cover"
            />
          </motion.div>

          <motion.div>
            <video className=" h-full w-full object-cover rounded-xl z-20" src={feature2} loop muted autoPlay />
          </motion.div>

          <motion.div>
          <Image
              width={600}
              height={553}
              src={feature3}
              alt="image 3"
              className="h-full w-full object-cover"
            />
          </motion.div>

        </ImageDisplay>
      </div>


    </section>

  );
}
