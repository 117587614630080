
// Icons
const Building = "/icons/menu_icons/building_icon_1.svg"
const SmartCity = "/icons/menu_icons/smart_city.svg"
const Careers = "/icons/menu_icons/careers_1.svg"
const FAQ = "/icons/menu_icons/faq.svg"
const Company = "/icons/menu_icons/company.svg"
const Contact = "/icons/menu_icons/contact_1.svg"
const Crowd = "/icons/menu_icons/crowd_icon_2-1.svg"
const Education = "/icons/menu_icons/education_1.svg"
const Healthcare = "/icons/menu_icons/healthcare_icon.svg"
const Home = "/icons/menu_icons/home_2.svg"
const Industries = "/icons/menu_icons/industries.svg"
const ItemIcon = "/icons/menu_icons/item.svg"
const Legal = "/icons/menu_icons/legal.svg"
const Partners = "/icons/menu_icons/partners.svg"
const Storefront = "/icons/menu_icons/storefront_1.svg"
const Transportation = "/icons/menu_icons/transport.svg"
const retailIcon = "/icons/menu_icons/retail.svg"
//images
const traffic = "/images/industries/traffic.png"
const classify = "/images/industries/classify.png"
const containers = "/images/industries/containers.png"
const animalDetection = "/images/industries/animalDetection.png"
const wrongWay = "/images/industries/wrong_way.png"
const people = "/images/industries/people.png"
const weapon = "/images/industries/weapon.png"
const abandoned_object = "/images/industries/abandoned_object.png"
const retail = "/images/industries/retail.png"
const parking = "/images/industries/parking.png"
const intrusion = "/images/industries/intrusion.png"
const road_accident = "/images/industries/road_accident.png"
const houseFire = "/images/industries/houseFire.png"

// predictions Icons
const CarIcon = "./icons/predictions/car.svg"
const BusIcon = "./icons/predictions/bus.svg"
const TruckIcon = "./icons/predictions/truck.svg"
const TrainIcon = "./icons/predictions/train.svg"
const Motorcycle = "./icons/predictions/motorcycle.svg"
const Bicycle = "./icons/predictions/bicycle.svg"

const Backpack = "./icons/predictions/backpack.svg"
const Handbag = "./icons/predictions/handbag.svg"
const AbandonedObjectIcon = "./icons/predictions/abandoned_object.svg"
const Suitcase = "./icons/predictions/suitcase.svg"

const Fire = "./icons/predictions/fire.svg"
const Smoke = "./icons/predictions/smoke.svg"
const FireRaising = "./icons/predictions/fire_raising.svg"

const PersonIcon = "./icons/predictions/person.svg"
const StandingUp = "./icons/predictions/standing_up.svg"
const Climbing = "./icons/predictions/climbing.svg"
const SittingDown = "./icons/predictions/sitting_down.svg"
const Riding = "./icons/predictions/riding.svg"
const Lying = "./icons/predictions/lying.svg"
const Throwing = "./icons/predictions/throwing.svg"
const Falling = "./icons/predictions/falling.svg"
const Standing = "./icons/predictions/standing.svg"
const Jumping = "./icons/predictions/jumping.svg"
const Walking = "./icons/predictions/walking.svg"
const Running = "./icons/predictions/running.svg"

const Graffiti = "./icons/predictions/graffiti.svg"
const Fighting = "./icons/predictions/fighting.svg"

//use cases images
const animals1 = "/images/industries/animals1.png"
const animals2 = "/images/industries/animals2.png"

const parkingLot = "/images/industries/parking.gif"
const parkingLot1 = "/images/industries/parking2.gif"
const parkingLot2 = "/images/industries/parking_lot2.png"
const intrusion1 = "/images/industries/intrusion1.png"
const intrusion2 = "/images/industries/intrusion2.png"
const intrusion3 = "/images/industries/intrusion3.png"
const classify1 = "/images/industries/classify1.png"
const crowd1 = "/images/industries/crowd.gif"
const crowd2 = "/images/industries/crowd1.png"
const crowd3 = "/images/industries/crowd2.gif"
const wrongWay2 = "/images/industries/wrong_way2.png"
const weapon1 = "/images/industries/weapon1.gif"
const weapon2 = "/images/industries/weapon2.gif"
const weapon3 = "/images/industries/weapon2.png"
const queue1 = "/images/industries/queue1.png"
const queue2 = "/images/industries/queue2.png"
const stealing = "/images/industries/stealing.png"
const stealing1 = "/images/industries/stealing1.gif"
const vandalism1 = "/images/industries/vandalism1.gif"
const abandoned_object1 = "/images/industries/abandoned_object1.gif"
const abandoned_object2 = "/images/industries/abandoned_object2.gif"
const abandoned_object3 = "/images/industries/abandoned_object1.png"
const crash1 = "/images/industries/crash1.gif"
const crash2 = "/images/industries/crash2.gif"
const fire1 = "/images/industries/fire1.gif"
const fire2 = "/images/industries/fire2.gif"
const fire3 = "/images/industries/fire3.gif"
const heatmap1 = "/images/industries/heatmap1.gif"
const heatmap2 = "/images/industries/heatmap2.gif"
const plate2 = "/images/industries/plate2.png"
//@ts-ignore
const healthcare = "/images/industries/healthcare.png"
const heatmap = "/images/industries/heatmap.png"
const license_plate = "/images/industries/license_plate.png"
const vehicle_brand_color = "/images/industries/vehicle_brand_color.png"

//industries videos
const fireDetection = "/videos/industries/fireDetection.mp4"
const heatmapVideo = "/videos/industries/heatmap.mp4"
const abandoned_object_video = "/videos/industries/abandoned_objects.mp4"
const stealing_video = "/videos/industries/stealing.mp4"
const crash_video = "/videos/industries/crash.mp4"
const crowd1_video = "/videos/industries/crowd1.mp4"
const home_anomalies = "/videos/industries/home_anomalies.mp4"
const vandalizing = "/videos/industries/vandalizing.mp4"

//partners & clients
const bosh = "/images/partners_clients/Bosh.png"
const CHCBeira_univ = "/images/partners_clients/CHCBeira_univ.png"
const CMA_Novo = "/images/partners_clients/CMA_Novo.png"
const Globalvia = "/images/partners_clients/Globalvia.png"
const Ascendi = "/images/partners_clients/Logo_Ascendi.png"
const Nos = "/images/partners_clients/Nos.png"
const Thales_Logo = "/images/partners_clients/Thales_Logo.png"
const avigilon = "/images/partners_clients/avigilon.png"
const ibm_softinsa = "/images/partners_clients/ibm_softinsa.png"
const milestone = "/images/partners_clients/milestone-systems.png"
const prosegur = "/images/partners_clients/prosegur.png"
const proav = "/images/partners_clients/LOGO-PROAV.png"



//TODO add vandalism and graffiti as use case and update menu link

export const ICONS = {
  building: Building,
  careers: Careers,
  company: Company,
  contact: Contact,
  crowd: Crowd,
  education: Education,
  healthcare: Healthcare,
  home: Home,
  industries: Industries,
  item: ItemIcon,
  legal: Legal,
  partners: Partners,
  storefront: retailIcon,
  transportation: Transportation,
  faq: FAQ
}
//------------------------------------------------------------------------------


export const SOLUTIONS_MENU = {
  title: 'menu.solutions',
  items: {
    first_column: [
      { id: "smart_city", name: "menu.smart_city", link: "/industries/smart_city", icon: SmartCity },
      { id: "house", name: "menu.house", link: "/industries/domestic", icon: Home },
      { id: "transportation", name: "menu.transportation", link: "/industries/transportation", icon: Transportation },
      { id: "retail", name: "menu.retail", link: "/industries/retail", icon: retailIcon },
    ],
    second_column: [
      { name: "menu.weapon_detection", link: "/solutions/weapon_detection", icon: ItemIcon, industries: ["smart_city", "retail", "house"] },
      { name: "menu.abandoned_objects", link: "/solutions/abandoned_luggage", icon: ItemIcon, industries: ["retail", "smart_city", "transportation", "government", "security"] },
      { name: "menu.queue_detection_and_people_counter", link: "/solutions/crowd_management", icon: ItemIcon, industries: [ "smart_city", "retail"] },
      { name: "menu.intrusions_and_disallowed_areas", link: "/solutions/intrusion", icon: ItemIcon, industries: ["smart_city", "retail", "house", "government", "security"] },
      // { name: "menu.vandalism_graffiti_damaging_detection", link:"/solutions/anomalies", icon: ItemIcon , industries:["smart_city", "government", "security"] },
      { name: "menu.movement_heat_maps", link: "/solutions/movement_heatmaps", icon: ItemIcon, industries: ["retail", "government", "security"] },
      { name: "menu.crash_detection", link: "/solutions/crash_detection", icon: ItemIcon, industries: ["transportation", "government", "security"] },
    ],
    third_column: [
      { name: "menu.environmental_monitoring", link: "/solutions/env_monitoring_fire_detection", icon: ItemIcon, industries: ["smart_city", "government"] },
      { name: "menu.parking_lot_occupancy", link: "/solutions/parking_lot", icon: ItemIcon, industries: ["smart_city", "transportation"] },
      { name: "menu.classify_filter_vehicles", link: "/solutions/classify_vehicle", icon: ItemIcon, industries: ["transportation"] },
      { name: "menu.wrong_way_direction", link: "/solutions/wrong_way_direction", icon: ItemIcon, industries: ["transportation"] },
      { name: "menu.animals_on_the_road", link: "/solutions/animal_detection", icon: ItemIcon, industries: ["transportation", "government"] },
      { name: "menu.queue_detection", link: "/solutions/queue_detection", icon: ItemIcon, industries: ["transportation"] },
    ],
  },
}


export const COMPANY_MENU: CompanyMenu = {
  title: 'company_menu_title',
  items: [
    { name: "company_menu_about", link: "/about", icon: "company" },
    { name: "company_menu_contact", link: "/contact", icon: "contact" },
    { name: "company_menu_partners", link: "/#partners", icon: "partners" },
    { name: "FAQ", link: "/faq", icon: "faq" },
    // { name: "company_menu_legal", link: "/terms", icon: "legal" },
  ],
}

//------------------------------------------------------------------------------

// Industries && use cases
export const INDUSTRIES = [
  {
    id: "transportation",
    name: 'industries_transportation_name',
    aspects: ['Road', 'Subway', 'Train', 'Harbour and airport'],
    label: "industries_transportation_label",
    title: "industries_transportation_title",
    subtitle: "industries_transportation_subtitle",
    description: "industries_transportation_description",
    cover: classify,
    examples: [
      {
        id: "subway",
        includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Anomalies'],
        name: "industries_transportation_examples_subway_name",
        title: "industries_transportation_examples_subway_title",
        description: "industries_transportation_examples_subway_description",
        video: crowd1_video,
        image: ""
      },
      {
        id: "road_crash",
        includes: ['Real-time detection', 'Notification', 'Classification', 'Anomalies'],
        name: "industries_transportation_examples_road_crash_name",
        title: "industries_transportation_examples_road_crash_title",
        description: "industries_transportation_examples_road_crash_description",
        video: crash_video,
        image: ""
      },
      {
        id: "vehicle_counting",
        name: "industries_transportation_examples_vehicle_counting_name",
        title: "industries_transportation_examples_vehicle_counting_title",
        description: "industries_transportation_examples_vehicle_counting_description",
        includes: ['Real-time counting', 'Data analysis', 'Classification'],
        image: classify1,
      },
    ]
  },
  {
    id: "smart_city",
    name: "industries_smart_city_name",
    label: "industries_smart_city_label",
    title: "industries_smart_city_title",
    subtitle: "industries_smart_city_subtitle",
    description: "industries_smart_city_description",
    aspects: ['Transportation', 'Crowd Analysis', 'Anomalies'],
    cover: parking,
    examples: [
      {
        id: "public_safety",
        name: "industries_smart_city_examples_public_safety_name",
        title: "industries_smart_city_examples_public_safety_title",
        description: "industries_smart_city_examples_public_safety_description",
        includes: ['Crowd Analysis', 'Anomaly Detection', 'Threat Identification'],
        video: abandoned_object_video
      },
      {
        id: "traffic_management",
        name: "industries_smart_city_examples_traffic_management_name",
        title: "industries_smart_city_examples_traffic_management_title",
        description: "industries_smart_city_examples_traffic_management_description",
        includes: ['Vehicle Counting', 'Congestion Analysis', 'Route Optimization'],
        tile: 'Efficient Traffic Management Powered by AI',
        image: queue1
      },
      {
        id: "environmental_monitoring",
        name: "industries_smart_city_examples_environmental_monitoring_name",
        title: "industries_smart_city_examples_environmental_monitoring_title",
        description: "industries_smart_city_examples_environmental_monitoring_description",
        includes: ['Fire Detection', 'Smoke Detection'],
        video: fireDetection,

      }

    ]

  },
  {
    id: "retail",
    name: "industries_retail_name",
    label: "industries_retail_label",
    title: "industries_retail_title",
    subtitle: "industries_retail_subtitle",
    description: "industries_retail_description",
    aspects: ['Crowd Analysis', 'Anomalies'],
    cover: heatmap,
    examples: [
      {
        id: "queue_management",
        name: "industries_retail_examples_queue_management_name",
        title: "industries_retail_examples_queue_management_title",
        description: "industries_retail_examples_queue_management_description",
        includes: ['People Counting', 'Queue Detection', 'Traffic Flow Analysis'],
        video: heatmapVideo
      },
      {
        id: "theft_prevention",
        name: "industries_retail_examples_theft_prevention_name",
        title: "industries_retail_examples_theft_prevention_title",
        description: "industries_retail_examples_theft_prevention_description",
        includes: ['Suspicious Behavior Detection', 'Unattended Items Detection'],
        video: stealing_video
      },
    ]

  },

  {
    id: "domestic",
    name: "industries_domestic_name",
    label: "industries_domestic_label",
    title: "industries_domestic_title",
    subtitle: "industries_domestic_subtitle",
    description: "industries_domestic_description",
    aspects: ['Intrusion Detection', 'Anomaly Recognition', 'Area Surveillance'],
    cover: intrusion,
    examples: [
      {
        id: "intrusion_detection",
        name: "industries_domestic_examples_intrusion_detection_name",
        title: "industries_domestic_examples_intrusion_detection_title",
        description: "industries_domestic_examples_intrusion_detection_description",
        includes: ['Real-time Alerts', 'Unauthorized Entry Detection'],
        video: vandalizing
      },
      {
        id: "anomaly_recognition",
        name: "industries_domestic_examples_anomaly_recognition_name",
        title: "industries_domestic_examples_anomaly_recognition_title",
        description: "industries_domestic_examples_anomaly_recognition_description",
        includes: ['Unusual Activity Detection', 'Safety Alerts'],
        video: home_anomalies
      },
    ]

  },
];

export const USE_CASES = [
  {
    id: "crowd_management",
    name: "crowd_management.name",
    title: "crowd_management.title",
    label: "crowd_management.label",
    description: 'crowd_management.description',
    industries: [{
      id: "smart_city",
      name: "Smart City"
    },
    {
      id: "health",
      name: "Health"
    },
    {
      id: "retail",
      name: "Retail"
    }
    ],
    includes: ['Counting', 'Tracking', 'Queues', 'Flow', 'Intrusion', 'Anomalies'],
    cover: people,
    examples: [
      crowd1,
      crowd3,
      crowd2,
    ]
  },
  {
    id: "animal_detection",
    name: "animal_detection.name",
    title: "animal_detection.title",
    label: "animal_detection.label",
    description: 'animal_detection.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "government", name: "Government" },
      { id: "domestic", name: "Domestic" }
    ],
    examples: [
      animals1,
      animals2
    ],
    cover: animalDetection
  },
  {
    id: "abandoned_luggage",
    name: "abandoned_luggage.name",
    title: "abandoned_luggage.title",
    label: "abandoned_luggage.label",
    description: 'abandoned_luggage.description',
    industries: [
      // { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" }
    ],
    examples: [
      abandoned_object2,
      abandoned_object1,
      abandoned_object3
    ],
    cover: abandoned_object
  },
  {
    id: "classify_vehicle",
    name: "classify_vehicle.name",
    title: "classify_vehicle.title",
    label: "classify_vehicle.label",
    description: 'classify_vehicle.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" }
    ],
    examples: [
      classify1,
    ],
    cover: classify
  },
  {
    id: "wrong_way_direction",
    name: "wrong_way_direction.name",
    title: "wrong_way_direction.title",
    label: "wrong_way_direction.label",
    description: 'wrong_way_direction.description',
    industries: [
      { id: "transportation", name: "Transportation" }
    ],
    examples: [
      wrongWay2,
    ],
    cover: wrongWay
  },
  {
    id: "parking_lot",
    name: "parking_lot.name",
    title: "parking_lot.title",
    label: "parking_lot.label",
    description: 'parking_lot.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" }
    ],
    examples: [
      parkingLot,
      parkingLot1,
      parkingLot2,
    ],
    cover: parking
  },
  {
    id: "queue_detection",
    name: "queue_detection.name",
    title: "queue_detection.title",
    label: "queue_detection.label",
    description: 'queue_detection.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" }
    ],
    examples: [
      queue2,
      queue1,
    ],
    cover: traffic
  },
  {
    id: "anomaly",
    name: "anomaly.name",
    title: "anomaly.title",
    label: "anomaly.label",
    description: 'anomaly.description',
    industries: [
      { id: "smart_city", name: "Smart City" },
      // { id: "security", name: "Security" },
      { id: "retail", name: "Retail" },
    ],
    examples: [
      stealing1,
      vandalism1,
    ],
    cover: stealing
  },

  {
    id: "weapon_detection",
    name: "weapon_detection.name",
    title: "weapon_detection.title",
    label: "weapon_detection.label",
    description: 'weapon_detection.description',
    industries: [
      { id: "smart_city", name: "Smart City" },
      // { id: "security", name: "Security" },
      { id: "retail", name: "Retail" },
      { id: "domestic", name: "Domestic" }
    ],
    examples: [
      weapon1,
      weapon2,
      weapon3
    ],
    cover: weapon
  },
  {
    id: "intrusion",
    name: "intrusion.name",
    title: "intrusion.title",
    label: "intrusion.label",
    description: 'intrusion.description',
    industries: [
      { id: "smart_city", name: "Smart City" },
      // { id: "security", name: "Security" },
      { id: "retail", name: "Retail" },
      { id: "domestic", name: "Domestic" },
    ],
    examples: [
      intrusion1,
      intrusion2
    ],
    cover: intrusion
  },
  {
    id: "crash_detection",
    name: "crash_detection.name",
    title: "crash_detection.title",
    label: "crash_detection.label",
    description: 'crash_detection.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" }
    ],
    examples: [
      crash1,
      crash2,
    ],
    cover: road_accident
  },
  {
    id: "movement_heatmaps",
    name: "movement_heatmaps.name",
    title: "movement_heatmaps.title",
    label: "movement_heatmaps.label",
    description: 'movement_heatmaps.description',
    industries: [
      { id: "smart_city", name: "Smart City" },
      { id: "retail", name: "Retail" }
    ],
    examples: [
      heatmap2,
      heatmap1,
    ],
    cover: heatmap
  },
  {
    id: "env_monitoring_fire_detection",
    name: "env_monitoring_fire_detection.name",
    title: "env_monitoring_fire_detection.title",
    label: "env_monitoring_fire_detection.label",
    description: 'env_monitoring_fire_detection.description',
    industries: [
      // {id: "environment", name: "Environment"},
      { id: "smart_city", name: "Smart City" },
      { id: "transportation", name: "Transportation" },
      { id: "government", name: "Government" }
    ],
    cover: houseFire,
    examples: [
      fire2,
      fire1,
      fire3,
    ],
  },
  // {
  //   id: "license_plate_identification",
  //   name: "license_plate_identification.name",
  //   title: "license_plate_identification.title",
  //   label: "license_plate_identification.label",
  //   description: "license_plate_identification.description",
  //   industries: [
  //     { id: "transportation", name: "Transportation" },
  //     { id: "government", name: "Government" },
  //     { id: "smart_city", name: "Smart City" }
  //   ],
  //   examples: [
  //     plate2,
  //   ],
  //   cover: license_plate,
  // },
  // {
  //   "id": "vehicle_brand_color_identification",
  //   "name": "vehicle_brand_color_identification.name",
  //   "title": "vehicle_brand_color_identification.title",
  //   "label": "vehicle_brand_color_identification.label",
  //   "description": "vehicle_brand_color_identification.description",
  //   "industries": [
  //     { "id": "transportation", "name": "Transportation" },
  //     { "id": "retail", "name": "Retail" },
  //     { "id": "smart_city", "name": "Smart City" },
  //     { id: "government", name: "Government" }
  //   ],
  //   examples: [
  //     plate2,
  //   ],
  //   cover: vehicle_brand_color
  // }

]
//------------------------------------------------------------------------------

// export const EXAMPLES = [
//   {
//     id: 1,
//     name: 'Crowd Management',
//     includes: ['Counting', 'Tracking', 'Queues', 'Flow', 'Intrusion', 'Anomalies'],
//     title: 'Streamlined Crowd Management',
//     description: 'Our AI-powered crowd management solution offers a customizable and scalable platform to meet your unique needs. With advanced queue detection and counting capabilities, you can optimize operations and reduce wait times, all while ensuring safety with features such as intrusion and anomaly detection. Our system is fully customizable, allowing you to tailor it to your specific requirements and preferences, making it the ideal solution for a wide range of environments and events.'
//   },
//   {
//     id: 2,
//     name: 'Environment Analysis',
//     includes: ['Fire and smoke detection and tracking', 'Animal and flora detection, classification'],
//     title: 'Enhancing Environmental Awareness with Real-Time Computer Vision',
//     description: 'Our advanced computer vision technology enables real-time detection and analysis of environmental factors such as fire and smoke, and identification of animal and plant species for conservation purposes.'
//   },
//   {
//     id: 3,
//     name: 'Road Traffic Control',
//     includes: ['Counting', 'Tracking', 'Classification', 'Queues', 'Stopped', 'Slow', 'Smoke', 'Accidents'],
//     title: 'Not Another Ordinary Object Detector: Next-Level Road Traffic Control Technology',
//     description: 'Revolutionize road traffic control with our state-of-the-art AI technology, which goes beyond traditional object detection and classification to provide comprehensive monitoring of traffic conditions and incidents. Upgrade to our advanced AI-powered solution that can track, classify, and analyze traffic patterns in real-time, providing invaluable insights for traffic management and optimization.'
//   },
//   {
//     id: 4,
//     name: 'Subway & Rail Control',
//     includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Anomalies'],
//     title: 'Transforming Subway & Rail Systems with Advanced AI Technology',
//     description: 'Leverage our innovative AI solutions to enhance subway and rail operations, improve safety, and optimize transportation efficiency. With capabilities such as counting, tracking, and crowd analysis, we offer a comprehensive solution for monitoring and managing subway and rail systems.'
//   },
//   {
//     id: 5,
//     name: 'Harbour and Offshore Control',
//     includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Safety'],
//     title: 'Streamlining Harbour and Offshore Operations with AI-Powered Technology',
//     description: 'Unlock the full potential of your harbour and offshore operations with our intelligent AI solutions. With capabilities such as counting, tracking, and safety monitoring, we provide a comprehensive system that enhances security and efficiency in maritime environments.'
//   },
//   {
//     id: 6,
//     name: 'Airport Control',
//     includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Anomalies'],
//     title: 'Revolutionizing Airport Operations with AI-Driven Insights',
//     description: 'Take your airport operations to the next level with our cutting-edge AI solutions. With features such as counting, tracking, and crowd analysis, we provide the tools you need to enhance safety, streamline operations, and provide a better passenger experience.'
//   }
// ];


export const PREDICTIONS_MENU = [
  { name: "Backpack", active: true, id: "backpack", icon: Backpack },
  { name: "Handbag", active: true, id: "handbag", icon: Handbag },
  { name: "Walking", active: true, id: "walking", icon: Walking, },
  { name: "Running", active: true, id: "running", icon: Running, },
  { name: "Standing", active: true, id: "standing", icon: Standing, },
  { name: "Standing up", active: true, id: "standing_up", icon: StandingUp, },
  { name: "Sitting down", active: true, id: "sitting_down", icon: SittingDown, },
  { name: "Jumping", active: true, id: "jumping", icon: Jumping, },
  { name: "Riding", active: true, id: "riding", icon: Riding, },
  { name: "Lying", active: true, id: "lying", icon: Lying, },
  { name: "Throwing", active: true, id: "throwing", icon: Throwing, },
  { name: "Falling", active: true, id: "falling", icon: Falling, },
  { name: "Fighting", active: true, id: "fighting", icon: Fighting },
  { name: "Suitcase", active: true, id: "suitcase", icon: Suitcase },
  { name: "Person", active: true, id: "person", icon: PersonIcon },
  { name: "Car", active: true, id: "car", icon: CarIcon },
  { name: "Bus", active: true, id: "bus", icon: BusIcon },
  { name: "Truck", active: true, id: "truck", icon: TruckIcon },
  { name: "Train", active: true, id: "train", icon: TrainIcon },
  { name: "Bicycle", active: true, id: "bicycle", icon: Bicycle },
  { name: "Motorcycle", active: true, id: "motorcycle", icon: Motorcycle },
  { name: "Smoke", active: true, id: "smoke", icon: Smoke },
  { name: "Fire", active: true, id: "fire", icon: Fire },
]

export const FAQs = [
    {
      "category": "Technical Questions",
      "questions": [
        {
          "question": "What types of solutions do you offer for AI surveillance?",
          "answer": "We provide both on-premises and cloud-based AI surveillance solutions. This allows our customers to choose the option that best fits their operational needs and security requirements."
        },
        {
          "question": "Can your AI models integrate with any existing surveillance system?",
          "answer": "Yes, our AI models are designed to be highly compatible and can be integrated with a wide range of existing surveillance systems, whether they are newer digital setups or older analog systems."
        }
      ]
    },
    {
      "category": "Detection Capabilities",
      "questions": [
        {
          "question": "What types of objects and actions can your AI models detect and identify?",
          "answer": "Our AI models can detect a wide variety of objects, including animals and vehicles, and identify various human behaviors and movements. This versatility ensures robust monitoring across diverse environments, enhancing security and safety. Our adaptable systems provide reliable surveillance solutions tailored to the unique needs of each location."
        },
        {
          "question": "Do your systems include weapon and other security-related detection?",
          "answer": "Yes, our AI models include advanced detection features for identifying weapons and other potential security threats, enhancing the capability to preemptively respond to incidents."
        },
        {
          "question": "How does your AI technology help in environmental safety?",
          "answer": "Our systems include smoke and fire detection capabilities, which are crucial for early warning and rapid response to prevent fires and reduce environmental and property damage."
        }
      ]
    },
    {
      "category": "Custom Solutions",
      "questions": [
        {
          "question": "Can you provide custom AI surveillance solutions based on specific needs?",
          "answer": "Absolutely, we specialize in crafting custom solutions tailored to the unique requirements of our customers. Whether you need specialized detection capabilities or integration with specific hardware, we can develop a solution that meets your needs."
        }
      ]
    },
    {
      "category": "Implementation and Support",
      "questions": [
        {
          "question": "What is the process for implementing your AI surveillance system?",
          "answer": "The implementation process starts with a consultation to assess your specific needs, followed by system design and integration. Our team ensures a smooth setup and deployment, with thorough testing and verification to guarantee optimal operation."
        },
        {
          "question": "What kind of support do you offer post-implementation?",
          "answer": "We offer comprehensive support post-implementation, including regular system updates, maintenance services, and technical support to ensure your AI surveillance system continues to operate efficiently and effectively."
        }
      ]
    }
  ]

export const PARTNERS = [
  Thales_Logo,
  bosh,
  CHCBeira_univ,
  CMA_Novo,
  Globalvia,
  Ascendi,
  Nos,
  avigilon,
  ibm_softinsa,
  milestone,
  prosegur,
  proav
]


type CompanyMenu = {
  title: string;
  items: {
    name: string;
    link: string;
    icon: IconType;
  }[];
}

export type UseCaseType = typeof USE_CASES[0];

export type IndustriesType = typeof INDUSTRIES[0];

export type IconType = keyof typeof ICONS;