import { motion } from 'framer-motion'
import { INDUSTRIES, USE_CASES, UseCaseType } from '../resources/constants';
import { Button } from './Button';
import { FADE_BOTTOM } from '../animations';
import { useEffect, useState } from 'react';
import { shuffleArray } from '../utils';
import { useTranslation } from 'next-i18next';
import { GridCard } from './GridCard';
import { useRouter } from 'next/router';
import Image from 'next/image';

interface UseCaseGridProps {
  useCase?: any;
}

export function UseCaseGrid({ useCase }: UseCaseGridProps) {
  const [useCases, setUseCases] = useState<UseCaseType[]>([])
  const router = useRouter();
  const { t, i18n } = useTranslation();

  const selectUseCase = (id: string) => {
    router.push(`/solutions/${id}`)
  }

  const handleSelectMore = () => {
    router.push(`/solutions`)
  }

  useEffect(() => {
    if (useCase) {
      const filteredUseCases = USE_CASES.filter(_useCase =>
        _useCase.industries.some(industry => useCase.industries.some((_industry: any) => _industry.id === industry.id))
      )
      const shuffledUseCases = shuffleArray(filteredUseCases);
      setUseCases(shuffledUseCases.slice(0, 3))
    }
  }, [useCase])


  return (
    <section className="w-full mt-28 relative dark:text-white text-neutral-600" >

      <div className="absolute inset-x-0 top-[0]  transform-gpu blur-3xl opacity-70">
        <svg
          className="relative left-[calc(50%)] h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-10rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#06b6d4" />
              <stop offset={1} stopColor="#2084F9" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="w-full mx-auto max-w-7xl py-10" >
        <motion.div
          className="w-full px-4 xl:px-0 flex relative items-center"
          transition={{
            duration: 0.3,
            delay: 0.3
          }}
          viewport={{ once: true }}
        >
          <motion.div
            // initial={{
            //   height: 0
            // }}
            whileInView={{
              height: 96
            }}
            transition={{
              duration: .6,
              delay: 0.1
            }}
            viewport={{ once: true }}
            className="bg-gradient-to-b from-primary-600 to-cyan-500  relative w-1 h-24"
          />

          <div className="ml-4" >
            <motion.h2
              initial={FADE_BOTTOM.initial}
              whileInView={FADE_BOTTOM.final}
              transition={{
                duration: 0.3,
                delay: 0.3
              }}
              viewport={{ once: true }}
              className="text-2xl sm:text-3xl font-bold tracking-tight" >{t("Related Use Cases")}
            </motion.h2>
          </div>

        </motion.div>

        {/* <motion.div
          className="w-[800px] bg-neutral-900 rounded-[3rem] px-28 py-12 border border-neutral-400 text-right relative"
          initial={{
            left: -900
          }}
          whileInView={{
            left: -500
          }}
          transition={{
            duration: 0.3,
            delay: 0.3
          }}
          viewport={{ once: true }}
        >
          <motion.h3
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.3,
              delay: 0.3
            }}
            viewport={{ once: true }}
            className="mt-2 text-lg sm:text-xl font-bold tracking-tight text-white ">{t("Related Use Cases")}
          </motion.h3>
        </motion.div> */}

        <div className="w-full mx-auto max-w-7xl " >
          <div id="cards" className="lg:mt-12 content-center object-center grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3 sm:gap-y-8 lg:gap-x-4 justify-items-center relative z-10">
            {useCases.map((i) => (
              <GridCard key={i.id} onSelect={() => selectUseCase(i.id)}>
                <div className="max-w-[260px] w-full h-[180px]  lg:h-[200px] overflow-hidden bg-neutral-200 rounded-2xl " >
                  <Image width={260} height={200} className="rounded-2xl group-hover:scale-125 duration-200" src={i.cover} alt={i.name} />
                </div>
                <div className="w-full text-center mt-4" >
                  <p className="text-xl" >
                    {t(`${i.name}`)}
                  </p>
                  <p className="mt-2 text-gray-500" >
                    {t(`${i.label}`)}
                  </p>
                </div>
              </GridCard>
            ))}
          </div>


          <div className="w-full mt-16 flex justify-center relative z-20" >
            <Button onClick={handleSelectMore} size="large" >{t("More")}</Button>
          </div>
        </div>
      </div>
    </section>
  );
}
