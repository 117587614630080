/**
 * Main Menu component
 */
import { useContext, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Context } from "./DropdownProvider";
import { useDimensions } from "./dimensions";
import { useTranslation } from "next-i18next";
//-----------------------------------------------------------------------------
interface DropdownOptionProps {
  name: string
  content: any
  backgroundHeight: number
  offset?: number
}
//-----------------------------------------------------------------------------
let lastOptionId = 0;

export function DropdownOption({ name, content: Content, backgroundHeight, offset }: DropdownOptionProps) {
  const idRef = useRef(++lastOptionId);
  const id = idRef.current;

  const [optionHook, optionDimensions] = useDimensions(true);
  const [registered, setRegistered] = useState(false);

  const {
    registerOption,
    updateOptionProps,
    deleteOptionById,
    setTargetId,
    targetId,
  } = useContext(Context);

  // Register dropdown option on Provider with its content dimensions
  useEffect(() => {
    // condition to prevent duplication when window dimension changes
    if (!registered && optionDimensions) {
      const WrappedContent = () => {
        const contentRef = useRef();

        useEffect(() => {
          //@ts-expect-error contentRef is possible null 
          const contentDimensions = contentRef.current.getBoundingClientRect();
          updateOptionProps(id, { contentDimensions });

        }, [contentRef]);
        return (
          // @ts-ignore
          <div ref={contentRef}>
            <Content />
          </div>
        );
      };
      registerOption({
        id,
        optionDimensions,
        optionCenterX: optionDimensions.x + optionDimensions.width / 2,
        WrappedContent,
        backgroundHeight,
        offset 
      });
      setRegistered(true);
    } else if (registered && optionDimensions) {
      // updates dimensions
      updateOptionProps(id, {
        optionDimensions,
        optionCenterX: optionDimensions.x + optionDimensions.width / 2,
      });
    }
  }, [
    registerOption,
    id,
    registered,
    optionDimensions,
    updateOptionProps,
    deleteOptionById,
    backgroundHeight,
    
  ]);

  useEffect(() => deleteOptionById(id), [deleteOptionById, id]);

  const handleOpen = () => setTargetId(id);
  const handleClose = () => setTargetId(null);
  //@ts-ignore
  //TODO handle mobile
  const handleTouch = () => (window.isMobile = true);

  const handleClick = (event: any) => {
    event.preventDefault();

    return targetId === id ? handleClose() : handleOpen();
  };
  const { t, i18n } = useTranslation();

  return (
    <motion.button
      className="dropdown-option h-8 font-bold "
      // @ts-expect-error element type
      ref={optionHook}
      onMouseDown={handleClick}
      onHoverStart={() => handleOpen()}
      onHoverEnd={() => handleClose()}
      onTouchStart={handleTouch}
      onFocus={handleOpen}
      onBlur={handleClose}
    >
      {t(`${name}`)}
    </motion.button>
  );
}