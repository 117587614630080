/**
 * Component for rendering the videos with custom configs and also responsible for 
 * rendering @VideoDrawer component
 */
//-------------------------------------------------------------------------
// react
import { useState, useRef, useEffect, Fragment, useCallback } from "react";
// libs
import { motion } from "framer-motion";
// components
import { VideoDrawer } from "./VideoDrawer";
// import { CustomCheckbox } from "./Checkbox";
//utils
import { getBrowserName } from "../utils/getBrowser";
// styles
//icons
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { classNames, convertVideoToYOLO, getVideoDetectedClasses, } from "../utils";
import { FrameType, VideoPredictionsType } from "../types/PredictionType";
import { PREDICTIONS_MENU } from "../resources/constants";

import { SvgPolygon } from "./SvgPolygon";
import { useTranslation } from 'next-i18next'
import Image from "next/image";
import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";


const FreeParkingIcon = "/icons/predictions/free_parking_space.svg"
const OccupiedParkingIcon = "/icons/predictions/occupied_parking_space.svg"
//-------------------------------------------------------------------------

interface VideoPlayerProps {
  /** Video source */
  videoUrl: string
  /** Formatted predictions type */
  predictions: VideoPredictionsType
  /** Video element size when on first render */
  imageSize: {
    width: number
    height: number
  }
  description: string
  metadata: any
  delimitedAreas: any
  areasType: "DEFAULT" | "PARKING" | null
  children: any
  videoId: string
}

type DataType = "YOLO" | "ACTION" | "DEFAULT" | null
type DataFormat = "csv" | "txt" | null
//-------------------------------------------------------------------------

// const parkingAreas = true

export function VideoPlayer({ children, delimitedAreas, videoUrl, imageSize, predictions, description, metadata, areasType, videoId }: VideoPlayerProps) {
  // refs
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<any | null>(null);
  const componentRef = useRef(null);
  // data sets
  const [currentFrame, setCurrentFrame] = useState<FrameType | null>(null);
  const [isPlaying, setIsPlaying] = useState(false)
  const [selectedObject, setSelectedObject] = useState<string | null>(null)
  const [detectedClasses, setDetectedClasses] = useState<Set<string>>()
  const [activeMenu, setActiveMenu] = useState<typeof PREDICTIONS_MENU>([])
  const [displayedMetadata, setDisplayedMetadata] = useState<any[]>([])
  const [selectedDataType, setSelectedDataType] = useState<DataType>("DEFAULT")
  const [resizedImage, setResizedImage] = useState<any>(null)
  const [displayedParkingAreas, setDisplayedParkingAreas] = useState({ free: true, occupied: true })
  const [activeAreas, setActiveAreas] = useState<any[]>(delimitedAreas)
  const [accordionOpen, setAccordionOpen] = useState(false)

  const { t } = useTranslation("common");

  //------------------------------------------------------------------

  useEffect(() => {
    const detected = getVideoDetectedClasses(predictions, ["person", "bus"])
    setDetectedClasses(detected)

    // if (detected) {
    // Filter the items in each category
    const filteredItems = PREDICTIONS_MENU.filter((item: any) => Array.from(detected).includes(item.id));
    setActiveMenu(filteredItems)
    // }
    setActiveAreas(delimitedAreas)

  }, [predictions])

  useEffect(() => {
    let arr = [] as any

    switch (selectedDataType) {
      case "YOLO":
        const yolo = convertVideoToYOLO(predictions, imageSize.width, imageSize.height, "txt")
        yolo[0].map((y) => {
          if (arr.length < 2) {
            arr.push(y)
          }
          if (arr.length === 2) {
            arr.push("...")
          }
        })
        setDisplayedMetadata(arr)
        break

      case "DEFAULT":
        const entries = Object.entries(metadata)
        entries.map(([key, outerValue]) => {
          // Iterate through the keys of "value"
          Object.entries(outerValue as any).forEach(([innerKey, innerValue]) => {
            let activeItem = activeMenu.find(item => item.id === innerKey.split("_")[0] && item.active);
            if (arr.length < 3 && activeItem) {
              arr.push(`${innerKey}: ${JSON.stringify(innerValue)}`)
            }
          });
        });

        if (arr.length === 3) {
          arr.push("...")
        }

        setDisplayedMetadata(arr)
    }

  }, [selectedDataType, metadata, activeMenu])


  const handleItemToggle = (itemID: string) => {
    const updatedItems = activeMenu.map((item, index) => {
      if (item.id === itemID) {
        return { ...item, active: !item.active };
      }
      return item;
    });

    setActiveMenu(updatedItems)
  };


  // format filter to send to video drawer
  const formatFilterMenu = () => {
    // 1. Use the reduce method on the menu array, with an initial value of an empty array.
    return activeMenu.reduce((acc, item) => {
      // 2. Filter the items in the current menu item, keeping only those that are active.
      const activeItems = activeMenu.filter((i) => i.active).map((i) => i.name.toLocaleLowerCase());

      // 3. Combine the accumulated active item names (acc) with the active item names found in the current menu item.
      return [...acc, ...activeItems];
    }, [] as string[]); // Set the initial value of the accumulator (acc) as an empty array.
  };


  // custom play and pause function to prevent default behavior
  const handleClick = (event: any) => {
    event.preventDefault();
    const video = videoRef.current;
    if (video && !video.paused) {
      video.pause();
      setIsPlaying(false)
    } else if (video) {
      video.play();
      setIsPlaying(true)
    }
  };

  //Update frame based on current video duration
  const updateFrame = () => {
    const video = document.getElementById("video") as HTMLVideoElement;
    const currentTime = video.currentTime

    if (!video || !predictions || predictions.length === 0) return;

    const frameRate = predictions.length / video.duration;
    const frameDuration = 1 / frameRate;
    const currentFrameIndex = Math.floor(currentTime / frameDuration);

    // Update the current frame
    if (currentFrameIndex < predictions.length) {
      setCurrentFrame(predictions[currentFrameIndex]);
    } else {
      setCurrentFrame(predictions[predictions.length - 1]);
    }
    if (video.currentTime === 0) {
      video.currentTime = 0.05;
    }

  };

  useEffect(() => {
    const video = document.getElementById('video') as any
    if (!video) return;
    const frameRate = predictions?.length / (video ? video.duration : 25); // set the frame rate of the video

    // Start the interval to update frames
    const intervalId = setInterval(updateFrame, 400 / 50);

    if (!isPlaying) {
      clearInterval(intervalId);
    }

    return () => {
      // Stop the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, [videoUrl, isPlaying, predictions]);

  useEffect(() => {
    if (predictions) {
      updateFrame()
      setIsPlaying(true)
    }
  }, [predictions, videoUrl])


  // gets image size and updates 
  const handleGetVideoSize = () => {
    const video = document.getElementById('video') as any
    if (video) {
      const { offsetWidth, offsetHeight } = video;
      setResizedImage({ width: offsetWidth, height: offsetHeight })
      return { width: offsetWidth, height: offsetHeight }
    } else {
      // if image is not rendered yet returns original measurements
      return { ...imageSize }
    }
  }

  // Event listener for resizing the image
  useEffect(() => {
    window.addEventListener('resize', handleGetVideoSize);
    // remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleGetVideoSize);
    };
  }, []);

  // Event listener for resizing the image when entering full screen
  useEffect(() => {
    const video = document.getElementById('video');
    if (!video) return;

    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === video) {
          handleGetVideoSize();
        }
      });
    });

    resizeObserver.observe(video);

    return () => {
      resizeObserver.unobserve(video);
    };
  }, [handleGetVideoSize]);

  const handleToggleArea = (area: any) => {
    setActiveAreas((prevActiveAreas) => {
      if (prevActiveAreas.includes(area)) {
        return prevActiveAreas.filter(_area => _area.ID !== area.ID);
      } else {
        return [...prevActiveAreas, area];
      }
    });
  }

  function formatDataForUrlParameter(dataArray: any, videoId: string) {
    const simplifiedData = dataArray.map((item: any) => ({
      name: item.TARGET_CLASS_NAME,
      POINTS: item.POINTS,
      COLOUR: item.COLOUR,
      ID: item.ID
    }));

    const dataWithVideoId = {
      videoId: videoId,
      data: simplifiedData,
      type: videoId === "parking" ? "PARKING" : "DEFAULT"
    };

    return encodeURIComponent(JSON.stringify(dataWithVideoId));
  }


  const handleNavigatePlayground = (videoId: string) => {
    const dataParam = formatDataForUrlParameter(delimitedAreas, videoId);
    const playgroundUrl = `https://playtool-d8f5a.web.app/?dataId=${videoId}`;
    // const playgroundUrl = `http://localhost:3001/?dataId=${videoId}`;
    // const playgroundUrl = `https://playground.deepneuronic.com?data=${dataParam}`;

    window.open(playgroundUrl, '_blank', 'noopener,noreferrer');
  }

  return (
    <motion.div ref={componentRef} tabIndex={-1} className={
      classNames(
        "outline-none ",
        "relative flex flex-col lg:flex-row w-full "
      )}
      transition={{
        ease: "easeOut",
        width: { duration: 0.2 },
        height: { duration: 0.2 },
        pointerEvents: { delay: 0.05 },
      }}
    >

      {videoUrl && (
        <div className={classNames("w-full flex items-center flex-col justify-center", "mt-2 lg:mt-8 ")} >
          <div className="flex flex-col lg:flex-row   lg:justify-center w-full gap-10 mb-4" >
            <div className={classNames("relative z-20 lg:min-w-[640px] lg:min-h-[360px]  ")}>
              <video
                className={classNames("z-10 shadow-sm shadow-blue-400", "max-h-[70vh] w-full  max-w-[840px] rounded-md")}
                preload="metadata"
                playsInline
                id="video"
                controls={false}
                ref={videoRef}
                src={`${videoUrl}`}
                onClick={(event) => handleClick(event)}
                // onTimeUpdate={updateFrame}
                loop
                muted
                autoPlay
              />

              {areasType === "PARKING" && delimitedAreas.length > 0 && delimitedAreas.map((area: any, index: any) => (
                <SvgPolygon
                  key={`polygon_${index}`}
                  onClick={(event) => handleClick(event)}
                  currentFrame={currentFrame}
                  originalHeight={imageSize.height}
                  originalWidth={imageSize.width}
                  displayedParkingAreas={displayedParkingAreas}
                  delimitedArea={area}
                  height={resizedImage.height}
                  width={resizedImage.width}
                  parkingArea={true}
                />
              ))}

              {areasType === "DEFAULT" && activeAreas.length > 0 && activeAreas.map((area: any, index: any) => (
                <SvgPolygon
                  key={`polygon_${index}`}
                  onClick={(event) => handleClick(event)}
                  currentFrame={currentFrame}
                  originalHeight={imageSize.height}
                  originalWidth={imageSize.width}
                  displayedParkingAreas={displayedParkingAreas}
                  delimitedArea={area}
                  height={resizedImage.height}
                  width={resizedImage.width}
                  parkingArea={false}
                />
              ))}

              {predictions && predictions?.length > 0 && currentFrame && (
                <VideoDrawer
                  isPlaying={isPlaying}
                  currentFrame={currentFrame}
                  frames={predictions}
                  originalImageSize={imageSize}
                  selectedObject={selectedObject}
                  filter={formatFilterMenu()}
                  delimitedAreas={areasType === "PARKING" ? delimitedAreas : activeAreas}
                />
              )}

              <div className="flex flex-col lg:flex-row justify-between">
                <div>
                  <div className="flex gap-4 select-none w-full mt-4" >
                    {activeMenu.map((i: any, index: any) => (
                      <div
                        key={`${i.id}`}
                        onClick={() => handleItemToggle(i.id)}
                        className={
                          classNames(
                            "py-1 px-4 min-w-[120px] max-w-[200px]  flex items-center justify-between border border-neutral-500/90 rounded",
                            i.active ? " dark:bg-neutral-800 bg-white  dark:shadow-sm shadow-black text-neutral-300" : "dark:hover:bg-neutral-900/10 hover:bg-neutral-100 text-neutral-400 dark:bg-background-900 bg-neutral-200",
                            "cursor-pointer hover:border-primary-500 dark:hover:border-white"
                          )}>
                        <span>{t(`${i.name}`)}</span>
                        <Image width={24} height={24} className={classNames(i.active ? "backdrop-brightness-125" : "brightness-[.4]", "w-6 rounded-full")} src={i.icon} alt={i.name} />
                      </div>
                    ))}
                  </div>

                  {areasType === "PARKING" && (
                    <div className="grid grid-cols-2 gap-4 select-none  max-w-[500px] mt-2 lg:mt-4" >
                      <div
                        onClick={() => setDisplayedParkingAreas((e) => ({ ...e, free: !e.free }))}
                        className={
                          classNames(
                            "py-1 px-4 min-w-[120px] max-w-[240px]  flex items-center justify-between border border-neutral-500/90 rounded",
                            displayedParkingAreas.free ? " dark:bg-neutral-800 bg-white  dark:shadow-sm shadow-black text-neutral-300" : "dark:hover:bg-neutral-900/10 hover:bg-neutral-100 text-neutral-400 dark:bg-background-900 bg-neutral-200",
                            "cursor-pointer hover:border-primary-500 dark:hover:border-white"
                          )}>
                        <span>{t(`Free parking spaces`)}</span>
                        <Image width={24} height={24} className={classNames(displayedParkingAreas.free ? "backdrop-brightness-125" : "brightness-[.4]", "w-6 rounded-full")} src={FreeParkingIcon} alt={"freeArea"} />
                      </div>

                      <div
                        onClick={() => setDisplayedParkingAreas((e) => ({ ...e, occupied: !e.occupied }))}
                        className={
                          classNames(
                            "py-1 px-4 min-w-[120px] max-w-[240px]  flex items-center justify-between border border-neutral-500/90 rounded",
                            displayedParkingAreas.occupied ? " dark:bg-neutral-800 bg-white  dark:shadow-sm shadow-black text-neutral-300" : "dark:hover:bg-neutral-900/10 hover:bg-neutral-100 text-neutral-400 dark:bg-background-900 bg-neutral-200",
                            "cursor-pointer hover:border-primary-500 dark:hover:border-white"
                          )}>
                        <span>{t(`Occupied parking spaces`)}</span>
                        <Image width={24} height={24} className={classNames(displayedParkingAreas.occupied ? "backdrop-brightness-125" : "brightness-[.4]", "w-6 rounded-full")} src={OccupiedParkingIcon} alt={"occupiedArea"} />
                      </div>
                    </div>
                  )}

                  {areasType === "DEFAULT" && (
                    <div className="flex gap-4 select-none w-full mt-4" >
                      {delimitedAreas.map((i: any, index: any) => (
                        <div
                          key={index}
                          onClick={() => handleToggleArea(i)}
                          className={
                            classNames(
                              "py-1 px-4 min-w-[120px] max-w-[240px]  flex items-center justify-between border border-neutral-500/90 rounded",
                              activeAreas.includes(i) ? " dark:bg-neutral-800 bg-white  dark:shadow-sm shadow-black text-neutral-300" : "dark:hover:bg-neutral-900/10 hover:bg-neutral-100 text-neutral-400 dark:bg-background-900 bg-neutral-200",
                              "cursor-pointer hover:border-primary-500 dark:hover:border-white"
                            )}>
                          <span>{t(`${i["TARGET_CLASS_NAME"].replaceAll(" • CLASS", "")}`)}</span>
                          <div
                            className={
                              classNames(
                                "w-6 h-4 block ml-2 rounded-xl",
                                activeAreas.includes(i) ? "opacity-100" : "opacity-50"
                              )
                            }
                            style={{
                              backgroundColor: ("rgba(" + i["COLOUR"].map((item: number) => (item + "")).join(", ") + ")"),
                            }} />
                        </div>
                      ))}

                    </div>
                  )}

                  {/* <Accordion open={accordionOpen} className="mb-2 rounded-lg  px-4 w-full  max-w-[840px] ">
                    <AccordionHeader onClick={() => setAccordionOpen(!accordionOpen)}
                      className={`border-b-0 transition-colors ${accordionOpen ? "text-blue-500 hover:!text-blue-700" : ""
                        }`}
                    >
                      <div className="w-full dark:h-[2px] h-[1px] bg-gradient-to-l from-neutral-500 to-background-900" ></div>
                      <p className="text-sm text-center text-green-600 mx-8" >METADATA</p>
                      <div className="w-full dark:h-[2px] h-[1px] bg-gradient-to-r from-neutral-500 to-background-900" ></div>
                    </AccordionHeader>
                    <AccordionBody>
                      {displayedMetadata.length > 0 && (
                        <div className="px-4 rounded-md" >
                          {displayedMetadata.map((preview) => (
                            <p key={preview} className="text-sm text-gray-500 my-1 truncate"
                              style={{ maxWidth: "500px" }}
                            >
                              {preview}
                            </p>
                          ))}
                        </div>
                      )}
                    </AccordionBody>
                  </Accordion> */}

                </div>

                <div className=" px-8 py-4 max-w-[300px]" >

                  <p className="text-sm text-gray-700 dark:text-gray-200" >
                    {t("Configure road areas and parking lots with DeepNeuronic Playground tool")}
                  </p>

                  <div onClick={() => handleNavigatePlayground(videoId)} className="flex mt-2 justify-center cursor-pointer text-primary-500 hover:underline" >
                    <p>
                      Try out Playground
                    </p>
                    <ArrowTopRightOnSquareIcon className="w-5 h-5 ml-2" />
                  </div>

                </div>
              </div>


            </div>

            <div className="lg:w-48 w-full max-h-[560px] " >
              {children}
            </div>
          </div>

          {/* controls */}
          <div className={classNames(
            " dark:bg-neutral-900  w-full rounded-lg p-8 relative shadow  group",
            "shadow-lg lg:hidden"
          )} >
            <h4 className="dark:text-gray-400 text-neutral-700" >
              {t(`${description}`)}
            </h4>
          </div>
        </div>
      )}

    </motion.div>
  );
}
